import axios from 'axios';
import authHeader from './auth-header';
import * as config from "../config/constants.config";

export default new class LogsService {
  getLogs(form) {
    const GETLOGS_URL = "getLogs";
    return axios.get(config.API_URL + GETLOGS_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }
}
