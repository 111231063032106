import axios from 'axios';
import authHeader from './auth-header';
import * as config from "../config/constants.config";

export default new class MembersService {
  getMembers() {
    const GETMEMBERS_URL = "getMembers";
    return axios.get(config.API_URL + GETMEMBERS_URL, { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  removeMember(form){
    const REMOVEMEMBER_URL = "removeMember";
    return axios.get(config.API_URL + REMOVEMEMBER_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

}
