import axios from 'axios';
import authHeader from './auth-header';
import * as config from "../config/constants.config";

export default new class ProcessesService {
  getMyCompanyProcesses() {
    const GETPROCESSES_URL = "getProcesses";
    return axios.get(config.API_URL + GETPROCESSES_URL, { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  getProcessVersions(form) {
    const GETPROCESSVERSIONS_URL = "getProcessVersions";
    return axios.get(config.API_URL + GETPROCESSVERSIONS_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  setProcessVersion(form) {
    const SETPROCESSVERSION_URL = "setProcessVersion";
    return axios.get(config.API_URL + SETPROCESSVERSION_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  addProcess(form){
    const ADDPROCESS_URL = "addProcess";
    return axios.get(config.API_URL + ADDPROCESS_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  uploadProcessVersion(processRef, version, file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('version', version);
    formData.append('processRef', processRef);
    const UPLOADPROCESSVERSION_URL = "uploadProcessVersion";
    return axios.post(config.API_URL + UPLOADPROCESSVERSION_URL, formData, { headers: authHeader(), "Content-Type": "multipart/form-data" }).then(response => {
      return response.data;
    });
  }

  editProcess(form){
    const EDITPROCESS_URL = "editProcess";
    return axios.get(config.API_URL + EDITPROCESS_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  removeProcess(form){
    const REMOVEPROCESS_URL = "removeProcess";
    return axios.get(config.API_URL + REMOVEPROCESS_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

}
