import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { FormattedMessage } from 'react-intl';
import * as constants from "../config/constants.config";

import AuthService from "../services/auth.service";

const required = value => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        <FormattedMessage
          id="forms.field.required.message"
          defaultMessage="This field is required!" />
      </div>
    );
  }
};

const vpassword = value => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The password must be between 6 and 40 characters.
      </div>
    );
  }
};

export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangePasswordConfirm = this.onChangePasswordConfirm.bind(this);
     
    var queryParams = new URLSearchParams(window.location.search);

    this.state = {
      password: "",
      passwordConfirm: "",
      successful: false,
      message: "",
      changepasswordid: queryParams.get('changepasswordid'),
      email: queryParams.get('email')
    };
    
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  onChangePasswordConfirm(e) {
    this.setState({
      passwordConfirm: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    if(this.state.password !== this.state.passwordConfirm){
      alert("Passwords don't match!");
    } else {
      this.setState({
        message: "",
        successful: false
      });
  
      this.form.validateAll();
  
      if (this.checkBtn.context._errors.length === 0) {
        AuthService.changePassword(
          this.state.email,
          this.state.password,
          this.state.changepasswordid
        ).then(
          response => {
            if(response.data.error && response.data.error.length > 0){
              this.setState({
                message: response.data.error,
                successful: false
              });
            } else {
              this.setState({
                message: "Password changed.",
                successful: true
              });
            }
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
  
            this.setState({
              successful: false,
              message: resMessage
            });
          }
        );
      }
      
    }

  }

  render() {

    // if (!currentUser && window.location.pathname !== "/"+constants.REGISTER_MASTER) {
    //   return (<Redirect to='/login' />);
    // } else {
      return (
        <div className="col-md-12">
          <div className="card card-container">

            <Form
              onSubmit={this.handleSubmit.bind(this)}
              ref={c => {
                this.form = c;
              }}
            >
              {!this.state.successful && (
                <div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChangePassword}
                      validations={[required, vpassword]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="passwordConfirm">Password confirmation</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="passwordConfirm"
                      value={this.state.passwordConfirm}
                      onChange={this.onChangePasswordConfirm}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <button className="btn btn-primary btn-block">Change password</button>
                  </div>

                </div>
              )}

              {this.state.message && (
                <div className="form-group">
                  <div
                    className={
                      this.state.successful
                        ? "alert alert-success"
                        : "alert alert-danger"
                    }
                    role="alert"
                  >
                    {this.state.message}
                  </div>
                </div>
              )}
              <CheckButton
                style={{ display: "none" }}
                ref={c => {
                  this.checkBtn = c;
                }}
              />
            </Form>
          </div>
        </div>
      );
    }
  // }
}