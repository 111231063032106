import React, { Component } from "react";
import ReactDOM from 'react-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, Modal, Form, OverlayTrigger, Tooltip, Dropdown, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faRedoAlt, faEllipsisV, faPlus, faPencilAlt, faTimesCircle, faCopy, faCheckCircle, faStopCircle, faMinusCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import MachinesService from "../../services/machines.service";

import { FormattedMessage } from 'react-intl';

export default class Machines extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalAddMachineShow: false,
      modalEditMachineShow: false,
      modalRemoveMachineShow: false,
      machines: [],
      newmachinename: '',
      newmachinedescription: '',
      newmachinelogpath: '',
      editmachineid: '',
      editmachinename: '',
      editmachinedescription: '',
      editmachinelogpath: '',
      removemachineid: '',
      removemachinename: '',
      removemachinemachinekey: ''
    };

  }

  setModalAddMachineShow(bool) {
    this.setState({ modalAddMachineShow: bool });
  }

  setModalEditMachineShow(bool) {
    this.setState({ modalEditMachineShow: bool });
  }

  setModalRemoveMachineShow(bool) {
    this.setState({ modalRemoveMachineShow: bool });
  }

  myChangeHandler(event) {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val })
  }

  addMachineSubmit() {
    if (this.state.newmachinename.trim().length > 0 &&
      this.state.newmachinedescription.trim().length > 0 &&
      this.state.newmachinelogpath.trim().length > 0) {

      MachinesService.addMachine({ name: this.state.newmachinename, description: this.state.newmachinedescription, logpath: this.state.newmachinelogpath }).then((res) => {
        this.showAlert("Machine added succesfully.", 'success');
        this.setModalAddMachineShow(false);
        this.loadMachines();        
      }).catch((err) => {
        this.showAlert("An error occours adding the new machine: " + err, 'danger');
        this.setModalAddMachineShow(false);
        this.loadMachines();
      })

    }

  }

  removeMachine(name, id, machineKey) {
    this.setState({ removemachineid: id, removemachinename: name, removemachinemachinekey: machineKey });
    this.setModalRemoveMachineShow(true);
  }

  removeMachineSubmit() {
    MachinesService.removeMachine({ id: this.state.removemachineid }).then((res) => {
      this.showAlert("Machine removed successfully", 'success');
      this.setModalRemoveMachineShow(false);
      this.loadMachines();
    }).catch((err) => {
      this.showAlert("An error occours removing the machine: " + err, 'danger');
      this.setModalRemoveMachineShow(false);
      this.loadMachines();
    })
  }

  editMachine(id, name, description, logpath) {
    this.setState({
      editmachineid: id,
      editmachinename: name,
      editmachinedescription: description,
      editmachinelogpath: logpath
    })
    this.setModalEditMachineShow(true);
  }

  editMachineSubmit() {
    
    if (this.state.editmachineid.toString().length > 0 &&
      this.state.editmachinename.trim().length > 0 &&
      this.state.editmachinedescription.trim().length > 0 &&
      this.state.editmachinelogpath.trim().length > 0) {

      MachinesService.editMachine({ id: this.state.editmachineid, name: this.state.editmachinename, description: this.state.editmachinedescription, logpath: this.state.editmachinelogpath }).then((res) => {
        this.showAlert("Machine edited successfully", 'success');
        this.setModalEditMachineShow(false);
        this.loadMachines();
      }).catch((err) => {
        this.showAlert("An error occours editing the machine: " + err, 'danger');
        this.setModalEditMachineShow(false);
        this.loadMachines();
      })

    }
  }

  loadMachines() {
    const self = this;

    MachinesService.getMyCompanyMachines().then(
      response => {
        self.setState({ machines: response.machines })
      },
      error => {
        if(error && error.toString().includes('code ')){
          var errCode = error.toString().substring(error.toString().indexOf('code ')+5, error.toString().indexOf('code ')+8)
          if(errCode === "401"){
            window.location.href = "/login";
          }
        } else {
          console.error(error);
        }
      }
    );
  }

  componentDidMount() {
    this.loadMachines();
  }

  render() {

    const columnsMachines = [{
      dataField: 'name',
      text: 'Name'
    }, {
      dataField: 'description',
      text: 'Description'
    }, {
      dataField: 'machineKey',
      text: 'Key', formatter: (cellContent, row) => {
        if (row.machineKey) {

          return (
            <div>
              <span>{row.machineKey}</span>
              <OverlayTrigger
                placement="right"
                delay={{ show: 150, hide: 400 }}
                overlay={<Tooltip>Copy to clipboard</Tooltip>}
              >
                <Button variant="transparent" onClick={() => { navigator.clipboard.writeText(row.machineKey) }}><FontAwesomeIcon icon={faCopy} /></Button>

              </OverlayTrigger>
            </div>
          );
        }
      }
    }, {
      dataField: 'status',
      text: 'Status', formatter: (cellContent, row) => {
        if (row.status) {
          
          if (new Date(row.wasOnlineAt) < new Date(new Date().getTime() - (60 * 60 * 1000))) {
            return (
              <div>
                <FontAwesomeIcon style={{ color: '#ED145B' }} icon={faMinusCircle} /> NOT RESPONDING
              </div>
            );
          } else {
            if (row.status == "IDLE" || row.status == "ENDED") {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#31d263' }} icon={faCheckCircle} /> {row.status}
                </div>
              );
            } else if (row.status == "RUNNING" || row.status == "PREPARING") {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#FB5124' }} icon={faStopCircle} /> {row.status}
                </div>
              );
            } else {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#ED145B' }} icon={faTimesCircle} /> {row.status}
                </div>
              );
            }

          }

        }
      }
    }, {
      dataField: 'action',
      isDummyField: true,
      text: <OverlayTrigger
        placement="right"
        delay={{ show: 150, hide: 400 }}
        overlay={<Tooltip><FormattedMessage id="pages.admin.machines.grid.buttons.refresh" defaultMessage="Refresh" /></Tooltip>}
      >
        <Button variant="light" onClick={this.loadMachines.bind(this)}><FontAwesomeIcon icon={faRedoAlt} /></Button>

      </OverlayTrigger>,
      formatter: (cellContent, row) => {
        if (row.machineKey) {
          console.log(row);
          
          return (
            <Dropdown>
              <OverlayTrigger
                placement="right"
                delay={{ show: 150, hide: 400 }}
                overlay={<Tooltip>Actions</Tooltip>}
              >
                <Dropdown.Toggle variant="light" disabled={(row.status == "RUNNING" || row.status == "PREPARING")}>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </Dropdown.Toggle>

              </OverlayTrigger>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => { this.editMachine(row.id, row.name, row.description, row.logPath) }}><FontAwesomeIcon icon={faPencilAlt} /> Edit machine</Dropdown.Item>
                <Dropdown.Item onClick={() => { this.removeMachine(row.name, row.id, row.machineKey) }}><FontAwesomeIcon icon={faTimesCircle} /> Remove machine</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          );
        }
      }
    }];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from} to { to} of { size} Results
      </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      noDataText: 'No data to show',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: 'All', value: this.state.machines.length
      }]
    };

    return (

      <div className="container">

        <div id="alert" style={{zIndex: 2000, position: 'absolute', top: '20px', left: '35%', minWidth: '500px', textAlign: 'center'}}></div>

        <h2><FontAwesomeIcon icon={faDesktop} /> <FormattedMessage
          id="pages.admin.machines.maintitle"
          defaultMessage="Machines" /></h2>

        <OverlayTrigger
          placement="right"
          delay={{ show: 150, hide: 400 }}
          overlay={<Tooltip><FormattedMessage id="pages.admin.machines.grid.buttons.addmachine" defaultMessage="Add machine" /></Tooltip>}
        >
          {this.state && this.state.machines &&
            <Button className={['btn-circle', 'btn-circle-right']} style={{left: '73%'}} variant="primary" onClick={() => this.setModalAddMachineShow(true)}><FontAwesomeIcon icon={faPlus} /></Button>
          }

        </OverlayTrigger>
        <div className={['div-container-border']}>

          {this.state && this.state.machines && this.state.machines.length > 0 &&
            <BootstrapTable hover keyField='machineKey' data={this.state.machines} columns={columnsMachines} pagination={paginationFactory(options)} />
          }

          {this.state && this.state.machines && this.state.machines.length == 0 &&
            <div><FontAwesomeIcon icon={faCircleNotch} spin /> Loading machines...</div>
          }

        </div>


        {/* Add machine modal */}
        <Modal
          show={this.state.modalAddMachineShow}
          onHide={() => this.setModalAddMachineShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add machine
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={((e) => { e.preventDefault(); })}>
              <Form.Group controlId="newMachineName">
                <Form.Label>Machine name</Form.Label>
                <Form.Control type="text" name="newmachinename" onChange={this.myChangeHandler.bind(this)} required placeholder="Enter machine name" />
              </Form.Group>

              <Form.Group controlId="newMachineDescription">
                <Form.Label>Machine description</Form.Label>
                <Form.Control type="text" name="newmachinedescription" onChange={this.myChangeHandler.bind(this)} required placeholder="Enter machine description" />
              </Form.Group>

              <Form.Group controlId="newMachineLogPath">
                <Form.Label>Machine logs path</Form.Label>
                <Form.Control type="text" name="newmachinelogpath" onChange={this.myChangeHandler.bind(this)} required placeholder="Enter machine log path folder" />
              </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalAddMachineShow(false)}>Close</Button>
            <Button variant="primary" type="submit" onClick={this.addMachineSubmit.bind(this)}>Submit</Button>
          </Modal.Footer>
        </Modal>

        {/* Edit machine modal */}
        <Modal
          show={this.state.modalEditMachineShow}
          onHide={() => this.setModalEditMachineShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit machine
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={((e) => { e.preventDefault(); })}>
              <Form.Group controlId="editMachineName">
                <Form.Label>Machine name</Form.Label>
                <Form.Control type="text" name="editmachinename" value={this.state.editmachinename} onChange={this.myChangeHandler.bind(this)} required placeholder="Enter machine name" />
              </Form.Group>

              <Form.Group controlId="editMachineDescription">
                <Form.Label>Machine description</Form.Label>
                <Form.Control type="text" name="editmachinedescription" value={this.state.editmachinedescription} onChange={this.myChangeHandler.bind(this)} required placeholder="Enter machine description" />
              </Form.Group>

              <Form.Group controlId="editMachineLogPath">
                <Form.Label>Machine logs path</Form.Label>
                <Form.Control type="text" name="editmachinelogpath" value={this.state.editmachinelogpath} onChange={this.myChangeHandler.bind(this)} required placeholder="Enter machine log path folder" />
              </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalEditMachineShow(false)}>Close</Button>
            <Button variant="primary" type="submit" onClick={this.editMachineSubmit.bind(this)}>Submit</Button>
          </Modal.Footer>
        </Modal>

        {/* Remove machine modal */}
        <Modal
          show={this.state.modalRemoveMachineShow}
          onHide={() => this.setModalRemoveMachineShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>Remove machine</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to remove '{this.state.removemachinename}' with MachineKey '{this.state.removemachinemachinekey}'</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalRemoveMachineShow(false)}>Close</Button>
            <Button variant="primary" onClick={this.removeMachineSubmit.bind(this)}>Remove</Button>
          </Modal.Footer>
        </Modal>

      </div>

    );
  }

  showAlert(text, type){
    ReactDOM.render(<Alert variant={type}>{text}</Alert>, document.getElementById('alert'));
    setTimeout(function(){ ReactDOM.unmountComponentAtNode(document.getElementById('alert')); }, 3000);
  }
}