import React, { Component } from "react";
import ReactDOM from 'react-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, Modal, Form, OverlayTrigger, Tooltip, Dropdown, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faEllipsisV, faPlus, faPencilAlt, faTimesCircle, faCopy, faCheckCircle, faStopCircle, faMinusCircle, faCircleNotch, faProjectDiagram } from '@fortawesome/free-solid-svg-icons'

import WorkflowsService from "../../services/workflows.service";

import { FormattedMessage } from 'react-intl';

export default class Workflows extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalAddWorkflowShow: false,
      modalEditWorkflowShow: false,
      modalRemoveWorkflowShow: false,
      workflows: [],
      getworkflowscallended: false,
      newworkflowname: '',
      newworkflowdescription: '',
      newworkflowlogpath: '',
      editworkflowid: '',
      editworkflowname: '',
      editworkflowdescription: '',
      editworkflowlogpath: '',
      removeworkflowid: '',
      removeworkflowname: ''
    };

  }

  setModalAddWorkflowShow(bool) {
    this.setState({ modalAddWorkflowShow: bool });
  }

  setModalEditWorkflowShow(bool) {
    this.setState({ modalEditWorkflowShow: bool });
  }

  setModalRemoveWorkflowShow(bool) {
    this.setState({ modalRemoveWorkflowShow: bool });
  }

  myChangeHandler(event) {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val })
  }

  addWorkflowSubmit() {
    if (this.state.newworkflowname.trim().length > 0 &&
      this.state.newworkflowdescription.trim().length > 0 &&
      this.state.newworkflowlogpath.trim().length > 0) {

      WorkflowsService.addWorkflow({ name: this.state.newworkflowname, description: this.state.newworkflowdescription, logpath: this.state.newworkflowlogpath }).then((res) => {
        this.showAlert("Workflow added succesfully.", 'success');
        this.setModalAddWorkflowShow(false);
        this.loadWorkflows();        
      }).catch((err) => {
        this.showAlert("An error occours adding the new workflow: " + err, 'danger');
        this.setModalAddWorkflowShow(false);
        this.loadWorkflows();
      })

    }

  }

  removeWorkflow(name, id) {
    this.setState({ removeworkflowid: id, removeworkflowname: name });
    this.setModalRemoveWorkflowShow(true);
  }

  removeWorkflowSubmit() {
    WorkflowsService.removeWorkflow({ id: this.state.removeworkflowid }).then((res) => {
      this.showAlert("Workflow removed successfully", 'success');
      this.setModalRemoveWorkflowShow(false);
      this.loadWorkflows();
    }).catch((err) => {
      this.showAlert("An error occours removing the workflow: " + err, 'danger');
      this.setModalRemoveWorkflowShow(false);
      this.loadWorkflows();
    })
  }

  editWorkflow(id, name, description, logpath) {
    this.setState({
      editworkflowid: id,
      editworkflowname: name,
      editworkflowdescription: description,
      editworkflowlogpath: logpath
    })
    this.setModalEditWorkflowShow(true);
  }

  editWorkflowSubmit() {
    
    if (this.state.editworkflowid.toString().length > 0 &&
      this.state.editworkflowname.trim().length > 0 &&
      this.state.editworkflowdescription.trim().length > 0 &&
      this.state.editworkflowlogpath.trim().length > 0) {

      WorkflowsService.editWorkflow({ id: this.state.editworkflowid, name: this.state.editworkflowname, description: this.state.editworkflowdescription, logpath: this.state.editworkflowlogpath }).then((res) => {
        this.showAlert("Workflow edited successfully", 'success');
        this.setModalEditWorkflowShow(false);
        this.loadWorkflows();
      }).catch((err) => {
        this.showAlert("An error occours editing the workflow: " + err, 'danger');
        this.setModalEditWorkflowShow(false);
        this.loadWorkflows();
      })

    }
  }

  loadWorkflows() {
    const self = this;
    self.setState({ getworkflowscallended: false })

    WorkflowsService.getWorkflows().then(
      response => {
        self.setState({ workflows: response.workflows, getworkflowscallended: true })
      },
      error => {
        self.setState({ getworkflowscallended: true })
        if(error && error.toString().includes('code ')){
          var errCode = error.toString().substring(error.toString().indexOf('code ')+5, error.toString().indexOf('code ')+8)
          if(errCode === "401"){
            window.location.href = "/login";
          }
        } else {
          console.error(error);
        }
      }
    );
  }

  componentDidMount() {
    this.loadWorkflows();
  }

  render() {

    const columnsWorkflows = [{
      dataField: 'name',
      text: 'Name'
    }, {
      dataField: 'description',
      text: 'Description'
    }, {
      dataField: 'status',
      text: 'Status', formatter: (cellContent, row) => {
        if (row.status) {
          
          if (new Date(row.wasOnlineAt) < new Date(new Date().getTime() - (60 * 60 * 1000))) {
            return (
              <div>
                <FontAwesomeIcon style={{ color: '#ED145B' }} icon={faMinusCircle} /> NOT RESPONDING
              </div>
            );
          } else {
            if (row.status == "IDLE" || row.status == "ENDED") {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#31d263' }} icon={faCheckCircle} /> {row.status}
                </div>
              );
            } else if (row.status == "RUNNING" || row.status == "PREPARING") {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#FB5124' }} icon={faStopCircle} /> {row.status}
                </div>
              );
            } else {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#ED145B' }} icon={faTimesCircle} /> {row.status}
                </div>
              );
            }

          }

        }
      }
    }, {
      dataField: 'action',
      isDummyField: true,
      text: <OverlayTrigger
        placement="right"
        delay={{ show: 150, hide: 400 }}
        overlay={<Tooltip><FormattedMessage id="pages.admin.workflows.grid.buttons.refresh" defaultMessage="Refresh" /></Tooltip>}
      >
        <Button variant="light" onClick={this.loadWorkflows.bind(this)}><FontAwesomeIcon icon={faRedoAlt} /></Button>

      </OverlayTrigger>,
      formatter: (cellContent, row) => {
        if (row.id) {
          console.log(row);
          
          return (
            <Dropdown>
              <OverlayTrigger
                placement="right"
                delay={{ show: 150, hide: 400 }}
                overlay={<Tooltip>Actions</Tooltip>}
              >
                <Dropdown.Toggle variant="light" disabled={(row.status == "RUNNING" || row.status == "PREPARING")}>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </Dropdown.Toggle>

              </OverlayTrigger>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => { this.editWorkflow(row.id, row.name, row.description) }}><FontAwesomeIcon icon={faPencilAlt} /> Edit workflow</Dropdown.Item>
                <Dropdown.Item onClick={() => { this.removeWorkflow(row.name, row.id) }}><FontAwesomeIcon icon={faTimesCircle} /> Remove workflow</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          );
        }
      }
    }];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from} to { to} of { size} Results
      </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      noDataText: 'No data to show',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: 'All', value: this.state.workflows.length
      }]
    };

    return (

      <div className="container">

        <div id="alert" style={{zIndex: 2000, position: 'absolute', top: '20px', left: '35%', minWidth: '500px', textAlign: 'center'}}></div>

        <h2><FontAwesomeIcon icon={faProjectDiagram} /> <FormattedMessage
          id="pages.admin.workflows.maintitle"
          defaultMessage="Workflows" /></h2>

        <OverlayTrigger
          placement="right"
          delay={{ show: 150, hide: 400 }}
          overlay={<Tooltip><FormattedMessage id="pages.admin.workflows.grid.buttons.addworkflow" defaultMessage="Add workflow" /></Tooltip>}
        >
          {this.state && this.state.workflows &&
            <Button className={['btn-circle', 'btn-circle-right']} style={{left: '73%'}} variant="primary" onClick={() => this.setModalAddWorkflowShow(true)}><FontAwesomeIcon icon={faPlus} /></Button>
          }

        </OverlayTrigger>
        <div className={['div-container-border']}>

          {this.state && this.state.workflows && this.state.workflows.length > 0 &&
            <BootstrapTable hover keyField='id' data={this.state.workflows} columns={columnsWorkflows} pagination={paginationFactory(options)} />
          }

          {this.state && !this.state.getworkflowscallended &&
            <div><FontAwesomeIcon icon={faCircleNotch} spin /> Loading workflows...</div>
          }

        </div>


        {/* Add workflow modal */}
        <Modal
          show={this.state.modalAddWorkflowShow}
          onHide={() => this.setModalAddWorkflowShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add workflow
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={((e) => { e.preventDefault(); })}>
              <Form.Group controlId="newWorkflowName">
                <Form.Label>Workflow name</Form.Label>
                <Form.Control type="text" name="newworkflowname" onChange={this.myChangeHandler.bind(this)} required placeholder="Enter workflow name" />
              </Form.Group>

              <Form.Group controlId="newWorkflowDescription">
                <Form.Label>Workflow description</Form.Label>
                <Form.Control type="text" name="newworkflowdescription" onChange={this.myChangeHandler.bind(this)} required placeholder="Enter workflow description" />
              </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalAddWorkflowShow(false)}>Close</Button>
            <Button variant="primary" type="submit" onClick={this.addWorkflowSubmit.bind(this)}>Submit</Button>
          </Modal.Footer>
        </Modal>

        {/* Edit workflow modal */}
        <Modal
          show={this.state.modalEditWorkflowShow}
          onHide={() => this.setModalEditWorkflowShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit workflow
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={((e) => { e.preventDefault(); })}>
              <Form.Group controlId="editWorkflowName">
                <Form.Label>Workflow name</Form.Label>
                <Form.Control type="text" name="editworkflowname" value={this.state.editworkflowname} onChange={this.myChangeHandler.bind(this)} required placeholder="Enter workflow name" />
              </Form.Group>

              <Form.Group controlId="editWorkflowDescription">
                <Form.Label>Workflow description</Form.Label>
                <Form.Control type="text" name="editworkflowdescription" value={this.state.editworkflowdescription} onChange={this.myChangeHandler.bind(this)} required placeholder="Enter workflow description" />
              </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalEditWorkflowShow(false)}>Close</Button>
            <Button variant="primary" type="submit" onClick={this.editWorkflowSubmit.bind(this)}>Submit</Button>
          </Modal.Footer>
        </Modal>

        {/* Remove workflow modal */}
        <Modal
          show={this.state.modalRemoveWorkflowShow}
          onHide={() => this.setModalRemoveWorkflowShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>Remove workflow</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to remove '{this.state.removeworkflowname}' with id '{this.state.removeworkflowid}'</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalRemoveWorkflowShow(false)}>Close</Button>
            <Button variant="primary" onClick={this.removeWorkflowSubmit.bind(this)}>Remove</Button>
          </Modal.Footer>
        </Modal>

      </div>

    );
  }

  showAlert(text, type){
    ReactDOM.render(<Alert variant={type}>{text}</Alert>, document.getElementById('alert'));
    setTimeout(function(){ ReactDOM.unmountComponentAtNode(document.getElementById('alert')); }, 3000);
  }
}