import axios from "axios";
import authHeader from './auth-header';
import * as config from "../config/constants.config";

class AuthService {
  login(email, password) {
    return axios
      .post(config.API_URL + "auth/signin", {
        email,
        password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(name, surname, email, tempPassword) {
    return axios.post(config.API_URL + "auth/signup", {
      name,
      surname,
      email,
      tempPassword
    }, {headers: authHeader()});
  }

  changePassword(email, password, changepasswordid) {
    return axios.post(config.API_URL + "changepassword", {
      email,
      password,
      changepasswordid
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();