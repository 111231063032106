import React, { Component } from "react";
import ReactDOM from 'react-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, Modal, Form, OverlayTrigger, Tooltip, Dropdown, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faEllipsisV, faPlus, faPencilAlt, faTimesCircle, faCopy, faCheckCircle, faStopCircle, faMinusCircle, faUsers } from '@fortawesome/free-solid-svg-icons'

import MembersService from "../../services/members.service";

import { FormattedMessage } from 'react-intl';

export default class Members extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modalRemoveMemberShow: false,
      removememberid: '',
      removemembername: '',
      members: []
    };

  }

  myChangeHandler(event) {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val })
  }

  setModalRemoveMemberShow(bool) {
    this.setState({ modalRemoveMemberShow: bool });
  }

  removeMember(name, id) {
    this.setState({ removememberid: id, removemembername: name });
    this.setModalRemoveMemberShow(true);
  }

  removeMemberSubmit() {
    MembersService.removeMember({ id: this.state.removememberid }).then((res) => {
      this.showAlert("Member removed successfully.", 'success');
      this.setModalRemoveMemberShow(false);
      this.loadMembers();
    }).catch((err) => {
      this.showAlert("An error occours removing the member: " + err, 'danger');
      this.setModalRemoveMemberShow(false);
    })
  }


  loadMembers() {
    const self = this;

    MembersService.getMembers().then(
      response => {
        self.setState({ members: response.members })
      },
      error => {
        if(error && error.toString().includes('code ')){
          var errCode = error.toString().substring(error.toString().indexOf('code ')+5, error.toString().indexOf('code ')+8)
          if(errCode === "401"){
            window.location.href = "/login";
          }
        } else {
          console.error(error);
        }
      }
    );
  }

  componentDidMount() {
    this.loadMembers();
  }

  render() {

    const columnsMembers = [{
      dataField: 'name',
      text: 'Name', formatter: (cellContent, row) => {
        if (row.name) {
          return (
            <div>
              {row.name} {row.surname}
            </div>
          );
        }
      }
    }, {
      dataField: 'email',
      text: 'Email'
    }, {
      dataField: 'roles',
      text: 'Roles', formatter: (cellContent, row) => {
        if (row.roles) {

          return row.roles.map((role, index) => {
            return (
              <div>
                {role.description}
              </div>
            );
            
          })

        }
      }
    }, {
      dataField: 'action',
      isDummyField: true,
      text: <OverlayTrigger
        placement="right"
        delay={{ show: 150, hide: 400 }}
        overlay={<Tooltip><FormattedMessage id="pages.admin.members.grid.buttons.refresh" defaultMessage="Refresh" /></Tooltip>}
      >
        <Button variant="light" onClick={this.loadMembers.bind(this)}><FontAwesomeIcon icon={faRedoAlt} /></Button>

      </OverlayTrigger>,
      formatter: (cellContent, row) => {
        if (row.id) {

          return (
            <Dropdown>
              <OverlayTrigger
                placement="right"
                delay={{ show: 150, hide: 400 }}
                overlay={<Tooltip><FormattedMessage id="pages.admin.members.grid.buttons.actions" defaultMessage="Actions" /></Tooltip>}
              >
                <Dropdown.Toggle variant="light" disabled={(row.status == "RUNNING" || row.status == "PREPARING")}>
                  <FontAwesomeIcon icon={faEllipsisV} />
                </Dropdown.Toggle>

              </OverlayTrigger>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => { this.removeMember(row.name+row.surname, row.id) }}><FontAwesomeIcon icon={faTimesCircle} /> Remove member</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          );
        }
      }
    }];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from} to { to} of { size} Results
      </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      noDataText: 'No data to show',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: 'All', value: this.state.members.length
      }]
    };

    return (

      <div className="container">

        <div id="alert" style={{zIndex: 2000, position: 'absolute', top: '20px', left: '35%', minWidth: '500px', textAlign: 'center'}}></div>

        <h2><FontAwesomeIcon icon={faUsers} /> <FormattedMessage
          id="pages.admin.members.maintitle"
          defaultMessage="Members" /></h2>

        <OverlayTrigger
          placement="right"
          delay={{ show: 150, hide: 400 }}
          overlay={<Tooltip><FormattedMessage id="pages.admin.members.grid.buttons.newmember" defaultMessage="Add member" /></Tooltip>}
        >
          {this.state && this.state.members &&
            <Button className={['btn-circle', 'btn-circle-right']} style={{left: '73%'}} variant="primary" onClick={() => window.location.href = "/register"}><FontAwesomeIcon icon={faPlus} /></Button>
          }

        </OverlayTrigger>
        <div className={['div-container-border']}>
          <BootstrapTable hover keyField='memberKey' data={this.state.members} columns={columnsMembers} pagination={paginationFactory(options)} />
        </div>

        {/* Remove member modal */}
        <Modal
          show={this.state.modalRemoveMemberShow}
          onHide={() => this.setmodalRemoveMemberShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>Remove member</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to remove '{this.state.removemembername}'</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setmodalRemoveMemberShow(false)}>Close</Button>
            <Button variant="primary" onClick={this.removeMemberSubmit.bind(this)}>Remove</Button>
          </Modal.Footer>
        </Modal>

      </div>

    );
  }

  showAlert(text, type){
    ReactDOM.render(<Alert variant={type}>{text}</Alert>, document.getElementById('alert'));
    setTimeout(function(){ ReactDOM.unmountComponentAtNode(document.getElementById('alert')); }, 3000);
  }
}