import React, { Component } from "react";
import ReactDOM from 'react-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, Modal, Form, OverlayTrigger, Tooltip, Dropdown, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShapes, faRedoAlt, faEllipsisV, faPlus, faPencilAlt, faTimesCircle, faBoxes, faUpload, faCircleNotch } from '@fortawesome/free-solid-svg-icons'

import ProcessesService from "../../services/processes.service";

import { FormattedMessage } from 'react-intl';

export default class Processes extends Component {

  constructor(props) {
    super(props);

    this.state = {
      processes: [],
      versions: [],
      versionsProcessRef: '',
      versionsProcessVersion: '',
      modalAddProcessShow: false,
      modalEditProcessShow: false,
      modalManageVersionsShow: false,
      modalRemoveProcessShow: false,
      modalSetProcessVersionShow: false,
      modalUploadProcessShow: false,
      newprocessactiveversion: '',
      newprocessname: '',
      newprocessdescription: '',
      uploadprocessname: '',
      uploadprocessversion: '',
      uploadprocessref: '',
      uploadprocessfile: '',
      editprocessid: '',
      editprocessname: '',
      editprocessdescription: '',
      removeprocessid: '',
      removeprocessname: '',
      removeprocessref: '',
      setprocessversionprocessref: '',
      setprocessversionversion: ''
    };

  }

  setModalAddProcessShow(bool) {
    this.setState({ modalAddProcessShow: bool });
  }

  setModalEditProcessShow(bool) {
    this.setState({ modalEditProcessShow: bool });
  }

  setModalManageVersionsShow(bool) {
    this.setState({ modalManageVersionsShow: bool });
  }

  setModalRemoveProcessShow(bool) {
    this.setState({ modalRemoveProcessShow: bool });
  }

  setModalSetProcessVersionShow(bool) {
    this.setState({ modalSetProcessVersionShow: bool });
  }

  setModalUploadProcessShow(bool) {
    this.setState({ modalUploadProcessShow: bool });
  }

  myChangeHandler(event) {
    let nam = event.target.name;
    let val = event.target.value;
    if(nam.includes("file")){
      val = Array.from(event.target.files)[0];
    }
    
    this.setState({ [nam]: val })
  }

  addProcessSubmit() {
    if (this.state.newprocessname.trim().length > 0 &&
      this.state.newprocessdescription.trim().length > 0) {

      ProcessesService.addProcess({ name: this.state.newprocessname, description: this.state.newprocessdescription }).then((res) => {
        this.showAlert("New process added successfully.", 'success');
        this.setModalAddProcessShow(false);
        this.loadProcesses();
      }).catch((err) => {
        this.showAlert("An error occours adding the new process: " + err, 'danger');
        this.setModalAddProcessShow(false);
        this.loadProcesses();
      });

    }

  }

  manageVersions(processRef, processVersion) {
    this.setState({versionsProcessRef: processRef, versionsProcessVersion: processVersion});
    setTimeout(() => { this.loadProcessVersions(); }, 500);
    this.setModalManageVersionsShow(true);
  }

  setProcessVersion(version, processRef) {
    this.setState({ setprocessversionprocessref: processRef, setprocessversionversion: version });
    this.setModalSetProcessVersionShow(true);
  }

  setProcessVersionSubmit() {
    ProcessesService.setProcessVersion({processRef: this.state.setprocessversionprocessref, version: this.state.setprocessversionversion}).then(() => {
      this.showAlert("Process version set successfully.", 'success');
      this.setModalSetProcessVersionShow(false);
      this.setModalManageVersionsShow(false);
      this.loadProcessVersions();
      this.loadProcesses();
    }).catch((err) => {
      this.showAlert("An error occours setting the process version: " + err, 'danger');
      this.setModalSetProcessVersionShow(false);
      this.loadProcessVersions();
      this.loadProcesses();
    });
  }

  removeProcess(name, id, processRef) {
    this.setState({ removeprocessid: id, removeprocessname: name, removeprocessref: processRef });
    this.setModalRemoveProcessShow(true);
  }

  removeProcessSubmit() {
    ProcessesService.removeProcess({ id: this.state.removeprocessid }).then((res) => {
      this.showAlert("Process removed successfully.", 'success');
      this.setModalRemoveProcessShow(false);
      this.loadProcesses();
    }).catch((err) => {
      this.showAlert("An error occours removing the process: " + err, 'danger');
      this.setModalRemoveProcessShow(false);
      this.loadProcesses();
    })
  }

  editProcess(id, name, description) {
    this.setState({
      editprocessid: id,
      editprocessname: name,
      editprocessdescription: description
    })
    this.setModalEditProcessShow(true);
  }

  editProcessSubmit() {
    if (this.state.editprocessid.trim().length > 0 &&
      this.state.editprocessname.trim().length > 0 &&
      this.state.editprocessdescription.trim().length > 0 &&
      this.state.editprocessdeslogpath.trim().length > 0) {

      ProcessesService.editProcess({ id: this.state.editprocessid, name: this.state.editprocessname, description: this.state.editprocessdescription }).then((res) => {
        this.showAlert("Process edited successfully.", 'success');
        this.setModalEditProcessShow(false);
        this.loadProcesses();
      }).catch((err) => {
        this.showAlert("An error occours editing the process: " + err, 'danger');
        this.setModalEditProcessShow(false);
        this.loadProcesses();
      })

    }
  }

  uploadProcess(processRef, version) {
    this.setState({uploadprocessref: processRef});
    this.setModalUploadProcessShow(true);
  }

  uploadProcessSubmit() {
    ProcessesService.uploadProcessVersion(this.state.uploadprocessref, this.state.uploadprocessversion, this.state.uploadprocessfile).then((res) => {
      this.setModalUploadProcessShow(false);
      this.loadProcesses();
    }).catch((err) => {
      this.setModalUploadProcessShow(false);
      this.loadProcesses();
    })
  }

  loadProcesses() {
    const self = this;

    ProcessesService.getMyCompanyProcesses().then(
      response => {
        self.setState({ processes: response.processes })
      },
      error => {
        if(error && error.toString().includes('code ')){
          var errCode = error.toString().substring(error.toString().indexOf('code ')+5, error.toString().indexOf('code ')+8)
          if(errCode === "401"){
            window.location.href = "/login";
          }
        } else {
          console.error(error);
        }
      }
    );
  }

  loadProcessVersions() {
    const self = this;

    ProcessesService.getProcessVersions({processRef: this.state.versionsProcessRef}).then(
      response => {
        self.setState({ versions: response.versions })
      },
      error => {
        if(error && error.toString().includes('code ')){
          var errCode = error.toString().substring(error.toString().indexOf('code ')+5, error.toString().indexOf('code ')+8)
          if(errCode === "401"){
            window.location.href = "/login";
          }
        } else {
          console.error(error);
        }
      }
    );
  }

  componentDidMount() {
    this.loadProcesses();
  }

  render() {

    const columnsProcesses = [{
      dataField: 'name',
      text: 'Name'
    }, {
      dataField: 'description',
      text: 'Description'
    }, {
      dataField: 'processRef',
      text: 'Process ref'
    }, {
      dataField: 'activeVersion',
      text: 'Version'
    }, {
      dataField: 'action',
      isDummyField: true,
      text: <OverlayTrigger
        placement="right"
        delay={{ show: 150, hide: 400 }}
        overlay={<Tooltip><FormattedMessage id="pages.admin.processes.grid.buttons.refresh" defaultMessage="Refresh" /></Tooltip>}
      >
        <Button variant="light" onClick={this.loadProcesses.bind(this)}><FontAwesomeIcon icon={faRedoAlt} /></Button>

      </OverlayTrigger>,
      formatter: (cellContent, row) => {
        if (row.processRef) {

          return (
            <Dropdown>
              <OverlayTrigger
                placement="right"
                delay={{ show: 150, hide: 400 }}
                overlay={<Tooltip><FormattedMessage id="pages.admin.processes.grid.buttons.actions" defaultMessage="Actions" /></Tooltip>}
              >
                <Dropdown.Toggle variant="light">
                  <FontAwesomeIcon icon={faEllipsisV} />
                </Dropdown.Toggle>

              </OverlayTrigger>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => { this.editProcess(row.id, row.name, row.description) }}><FontAwesomeIcon icon={faPencilAlt} /> Edit process</Dropdown.Item>
                <Dropdown.Item onClick={() => { this.manageVersions(row.processRef, row.activeVersion) }}><FontAwesomeIcon icon={faBoxes} /> Manage versions</Dropdown.Item>
                <Dropdown.Item onClick={() => { this.uploadProcess(row.processRef) }}><FontAwesomeIcon icon={faPlus} /> Upload version</Dropdown.Item>
                <Dropdown.Item onClick={() => { this.removeProcess(row.name, row.id, row.processRef) }}><FontAwesomeIcon icon={faTimesCircle} /> Remove process</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          );
        }
      }
    }];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from} to { to} of { size} Results
      </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      noDataText: 'No data to show',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: 'All', value: this.state.processes.length
      }]
    };

    const columnsVersions = [{
      dataField: 'version',
      text: 'Version'
    },{
      dataField: 'creation',
      text: 'Date uploaded',
      formatter: (cellContent, row) => {
        return(<span> {new Date(row.creation).toString()} </span>);
      }
    }, {
      dataField: 'action',
      isDummyField: true,
      text: <OverlayTrigger
        placement="right"
        delay={{ show: 150, hide: 400 }}
        overlay={<Tooltip><FormattedMessage id="pages.admin.processes.grid.buttons.refresh" defaultMessage="Refresh" /></Tooltip>}
      >
        <Button variant="light" onClick={this.loadProcessVersions.bind(this)}><FontAwesomeIcon icon={faRedoAlt} /></Button>

      </OverlayTrigger>,
      formatter: (cellContent, row) => {
        return(
          
            <div> {this.state && this.state.versionsProcessVersion != row.version &&
              <OverlayTrigger
                placement="right"
                delay={{ show: 150, hide: 400 }}
                overlay={<Tooltip><FormattedMessage id="pages.admin.processes.grid.buttons.setVersion" defaultMessage="Set version" /></Tooltip>}
              >
                <Button variant="light" onClick={() => this.setProcessVersion(row.version, this.state.versionsProcessRef)}><FontAwesomeIcon icon={faUpload} /></Button>

              </OverlayTrigger>
              }

              {this.state && this.state.versionsProcessVersion == row.version && <span>Actual</span>}
            </div>
          
        );
      }
    }];

    const optionsVersions = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      noDataText: 'No data to show',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: 'All', value: this.state.versions.length
      }]
    };

    return (

      <div className="container">

        <div id="alert" style={{zIndex: 2000, position: 'absolute', top: '20px', left: '35%', minWidth: '500px', textAlign: 'center'}}></div>

        <h2><FontAwesomeIcon icon={faShapes} /> <FormattedMessage
          id="pages.admin.processes.maintitle"
          defaultMessage="Processes" /></h2>

        <OverlayTrigger
          placement="right"
          delay={{ show: 150, hide: 400 }}
          overlay={<Tooltip><FormattedMessage id="pages.admin.processes.grid.buttons.addprocess" defaultMessage="Add process" /></Tooltip>}
        >
          {this.state && this.state.processes &&
            <Button className={['btn-circle', 'btn-circle-right']} style={{left: '73%'}} variant="primary" onClick={() => this.setModalAddProcessShow(true)}><FontAwesomeIcon icon={faPlus} /></Button>
          }

        </OverlayTrigger>
        <div className={['div-container-border']}>
          <BootstrapTable hover keyField='id' data={this.state.processes} columns={columnsProcesses} pagination={paginationFactory(options)} />
        </div>

        <Modal
          show={this.state.modalAddProcessShow}
          onHide={() => this.setModalAddProcessShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add process
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={((e) => { e.preventDefault(); })}>
              <Form.Group controlId="newProcessName">
                <Form.Label>Process name</Form.Label>
                <Form.Control type="text" name="newprocessname" onChange={this.myChangeHandler.bind(this)} required placeholder="Enter process name" />
              </Form.Group>

              <Form.Group controlId="newProcessDescription">
                <Form.Label>Process description</Form.Label>
                <Form.Control type="text" name="newprocessdescription" onChange={this.myChangeHandler.bind(this)} required placeholder="Enter process description" />
              </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalAddProcessShow(false)}>Close</Button>
            <Button variant="primary" type="submit" onClick={this.addProcessSubmit.bind(this)}>Submit</Button>
          </Modal.Footer>
        </Modal>

        {/* Upload version */}
        <Modal
          show={this.state.modalUploadProcessShow}
          onHide={() => this.setModalUploadProcessShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Upload process
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="uploadProcessVersion">
                <Form.Label>Process version</Form.Label>
                <Form.Control type="text" name="uploadprocessversion" onChange={this.myChangeHandler.bind(this)} required placeholder="Enter this process version name" />
              </Form.Group>

              <Form.Group controlId="uploadProcessFile">
                <Form.Label>Process file</Form.Label>
                <Form.Control type="file" name="uploadprocessfile" onChange={this.myChangeHandler.bind(this)} required />
              </Form.Group>
              
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalUploadProcessShow(false)}>Close</Button>
            <Button variant="primary" type="submit" onClick={this.uploadProcessSubmit.bind(this)}>Submit</Button>
          </Modal.Footer>
        </Modal>

        {/* Manage versions */}
        <Modal
          show={this.state.modalManageVersionsShow}
          onHide={() => this.setModalManageVersionsShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Manage versions
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <BootstrapTable hover keyField='id' data={this.state.versions} columns={columnsVersions} pagination={paginationFactory(optionsVersions)} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalManageVersionsShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        {/* Edit process modal */}
        <Modal
          show={this.state.modalEditProcessShow}
          onHide={() => this.setModalEditProcessShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit process
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={((e) => { e.preventDefault(); })}>
              <Form.Group controlId="editProcessName">
                <Form.Label>Process name</Form.Label>
                <Form.Control type="text" name="editprocessname" value={this.state.editprocessname} onChange={this.myChangeHandler.bind(this)} required placeholder="Enter machine name" />
              </Form.Group>

              <Form.Group controlId="editProcessDescription">
                <Form.Label>Process description</Form.Label>
                <Form.Control type="text" name="editprocessdescription" value={this.state.editprocessdescription} onChange={this.myChangeHandler.bind(this)} required placeholder="Enter machine description" />
              </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalEditProcessShow(false)}>Close</Button>
            <Button variant="primary" type="submit" onClick={this.editProcessSubmit.bind(this)}>Submit</Button>
          </Modal.Footer>
        </Modal>

        {/* Remove process modal */}
        <Modal
          show={this.state.modalRemoveProcessShow}
          onHide={() => this.setModalRemoveProcessShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>Remove process</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to remove '{this.state.removeprocessname}' with ProcessRef '{this.state.removeprocessref}'</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalRemoveProcessShow(false)}>Close</Button>
            <Button variant="primary" onClick={this.removeProcessSubmit.bind(this)}>Remove</Button>
          </Modal.Footer>
        </Modal>


        {/* Set process version modal */}
        <Modal
          show={this.state.modalSetProcessVersionShow}
          onHide={() => this.setModalSetProcessVersionShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>Set process version</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to set version '{this.state.setprocessversionversion}' to ProcessRef '{this.state.setprocessversionprocessref}'</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalSetProcessVersionShow(false)}>Close</Button>
            <Button variant="primary" onClick={this.setProcessVersionSubmit.bind(this)}>Confirm</Button>
          </Modal.Footer>
        </Modal>

      </div>

    );
  }

  showAlert(text, type){
    ReactDOM.render(<Alert variant={type}>{text}</Alert>, document.getElementById('alert'));
    setTimeout(function(){ ReactDOM.unmountComponentAtNode(document.getElementById('alert')); }, 3000);
  }
}