import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser()
    };
  }


  render() {
    const { currentUser } = this.state;

    if(!currentUser){
      return (<Redirect to='/login' />);
    }
    return (<Redirect to='/admin/executions' />);
  }
}