import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faPlayCircle, faProjectDiagram, faBolt, faUsers, faShapes } from '@fortawesome/free-solid-svg-icons'


import AuthService from "../services/auth.service";

import Machines from "./companyadmin/machines.component";
import Processes from "./companyadmin/processes.component";
import Executions from "./companyadmin/executions.component";
import Workflows from "./companyadmin/workflows.component";
import Logs from "./companyadmin/logs.component";
import Triggers from "./companyadmin/triggers.component";
import Members from "./companyadmin/members.component";

import { FormattedMessage } from 'react-intl';

export default class BoardAdmin extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser()
    };

  }

  render() {
    const { currentUser } = this.state;

    if (!currentUser) {
      return (<Redirect to='/login' />);
    } else if (!currentUser.roles.includes('ROLE_COMPANYADMIN')) {
      return (<Redirect to='/profile' />);
    }

    return (
      <Router>
        <div className="" style={{ marginTop: '-100vh' }}>
          <Row>
            <Col xs={1} id="sidebar-wrapper">
              <nav className="navbar navbar-expand navbar-dark bg-dark sidebar">
                <div className="navbar-nav">
                  <ul>
                    <li className="nav-item">
                      <Link to={"/admin/machines"} className="nav-link">
                        <FontAwesomeIcon icon={faDesktop} /> {'\u00A0'}{'\u00A0'}
                        <FormattedMessage
                          id="pages.admin.navbar.machines"
                          defaultMessage="Machines" />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={"/admin/processes"} className="nav-link">
                        <FontAwesomeIcon icon={faShapes} /> {'\u00A0'}
                        <FormattedMessage
                          id="pages.admin.navbar.processes"
                          defaultMessage="Processes" />
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={"/admin/executions"} className="nav-link">
                        <FontAwesomeIcon icon={faPlayCircle} /> {'\u00A0'}{'\u00A0'}
                        <FormattedMessage
                          id="pages.admin.navbar.executions"
                          defaultMessage="Executions" />
                      </Link>
                    </li>
                    {/* <li className="nav-item">
                      <Link to={"/admin/workflows"} className="nav-link">
                        <FontAwesomeIcon icon={faProjectDiagram} /> {'\u00A0'}{'\u00A0'}
                        <FormattedMessage
                          id="pages.admin.navbar.workflows"
                          defaultMessage="Workflows" />
                      </Link>
                    </li> */}
                    <li className="nav-item">
                      <Link to={"/admin/triggers"} className="nav-link">
                        <FontAwesomeIcon icon={faBolt} /> {'\u00A0'}{'\u00A0'}{'\u00A0'}{'\u00A0'}
                        <FormattedMessage
                          id="pages.admin.navbar.triggers"
                          defaultMessage="Triggers" />
                      </Link>
                    </li>
                    <br></br>
                    <li className="nav-item">
                      <Link to={"/admin/members"} className="nav-link">
                        <FontAwesomeIcon icon={faUsers} /> {'\u00A0'}
                        <FormattedMessage
                          id="pages.admin.navbar.members"
                          defaultMessage="Members" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </Col>
            <Col xs={'auto'} id="page-content-wrapper">
              <div className="" style={{ maxWidth: '82vw' }}>
                <Switch>
                  <Route exact path="/admin/machines" component={Machines} />
                  <Route exact path="/admin/processes" component={Processes} />
                  <Route exact path="/admin/executions" component={Executions} />
                  <Route exact path="/admin/workflows" component={Workflows} />
                  <Route exact path="/admin/triggers" component={Triggers} />
                  <Route exact path="/admin/members" component={Members} />
                  <Route path="/admin/executions/logs/" component={Logs} />
                </Switch>
              </div>
            </Col>
          </Row>

        </div>
      </Router>
    );
  }
}