import React, { Component } from "react";
import ReactDOM from 'react-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, Modal, Form, Dropdown, OverlayTrigger, Tooltip, Tabs, Tab, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faEllipsisV, faPlay, faBox, faStopCircle, faEllipsisH, faTimesCircle, faMinusCircle, faCircleNotch, faCheckCircle, faBolt, faMitten, faList, faInfoCircle, faClock, faPlayCircle } from '@fortawesome/free-solid-svg-icons'

import ProcessesService from "../../services/processes.service";
import MachinesService from "../../services/machines.service";
import ExecutionsService from "../../services/executions.service";

import { FormattedMessage } from 'react-intl';

export default class Executions extends Component {

  constructor(props) {
    super(props);

    this.state = {
      executions: [],
      parsedExecutions: [],
      machines: [],
      processes: [],
      modalAddExecutionShow: false,
      modalAddExecutionPackageShow: false,
      newexecutionpackagecode: '',
      newexecutionpackagedescription: '',
      modalInfoExecutionShow: false,
      newexecutionmachineid: '',
      newexecutionprocessid: '',
      newexecutiontype: 'Robot',
      newexecutioncommand: '',
      newexecutionparameters: '',
      stopexecutionid: '',
      stopexecutionmachineid: '',
      killexecutionid: '',
      killexecutionmachineid: '',
      machinesselected: []
    };

    this.loadProcesses();

  }

  /* Table behaviour */
  handleOnSelectMachines(row, isSelect) {

    if (isSelect) {
      if (row.status === "IDLE" && !(new Date(row.wasOnlineAt) < new Date(new Date().getTime() - (60 * 60 * 1000)))) {
        this.setState({
          machinesselected: this.state.machinesselected.concat(row.id)
        });
      } else {
        this.showAlert("This machine is already running or is disconnected.", 'danger');
        return false;
      }

    } else {
      this.setState({
        machinesselected: this.state.machinesselected.filter(x => x !== row.id)
      });
    }

  }

  handleOnSelectAllMachines(isSelect, rows) {
    const machines = rows.filter(r => (r.status === "IDLE" && !(new Date(r.wasOnlineAt) < new Date(new Date().getTime() - (60 * 60 * 1000))))).map(r => r.id);
    if (isSelect) {
      this.setState({
        machinesselected: machines
      });
    } else {
      this.setState({
        machinesselected: []
      });
    }
  }

  componentDidMount() {
    this.loadExecutions();
  }

  loadDisponibleMachines() {
    this.setState({machines: []});
    MachinesService.getMyCompanyMachines().then((res) => {
      this.setState({machines: res.machines});
    })
  }

  renderMachines() {
    return this.state.machines.map((machine, index) => {
      if (machine.status == "IDLE") {
        return (
          <option key={machine.id} value={machine.id}>{machine.name + " - " + machine.machineKey}</option>
        )
      }

    })
  }

  loadProcesses() {
    ProcessesService.getMyCompanyProcesses().then((res) => {
      this.state.processes = res.processes;

    })
  }

  renderProcesses() {
    return this.state.processes.map((process, index) => {
      return (
        <option key={process.id} value={process.id}>{process.name + " - " + process.processRef}</option>
      )

    })
  }

  setModalAddExecutionShow(bool) {
    this.loadDisponibleMachines();
    this.setState({ modalAddExecutionShow: bool });
  }

  setModalAddExecutionPackageShow(bool) {
    this.loadDisponibleMachines();
    this.setState({ modalAddExecutionPackageShow: bool });
  }

  setModalInfoExecutionShow(bool) {
    this.setState({ modalInfoExecutionShow: bool });
  }

  myChangeHandler(event) {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val })

    if(this.state.newexecutiontype == "Robot"){
      this.setState({ ['newexecutioncommand']: '' })
    } else if (this.state.newexecutiontype == "Command"){
      this.setState({ ['newexecutionprocessid']: '' })
    }

  }

  addExecutionSubmit() {

    if (this.state.machinesselected.length > 0 &&
      (this.state.newexecutionprocessid.trim().length > 0 || this.state.newexecutioncommand.trim().length > 0)) {

      var params = this.state.newexecutionparameters;
      if (params.length === 0) {
        params = "{}";
      }

      ExecutionsService.addExecution({ type: this.state.newexecutiontype, command: this.state.newexecutioncommand, machine_id: this.state.machinesselected, process_id: this.state.newexecutionprocessid, parameters: params, origin: 'MANUAL' }).then(() => {
        this.showAlert("New execution added successfully.", 'success');
        this.setModalAddExecutionShow(false);
        this.loadExecutions();
      }).catch((err) => {
        this.showAlert("An error occours adding the new execution: " + err, 'danger');
        this.setModalAddExecutionShow(false);
      });

      this.setState({machinesselected: []})

    }

  }

  stopExecutionSubmit(executionId, robotstatus) {
    if (executionId.trim().length > 0 && robotstatus.trim().length > 0) {

      if (robotstatus == "RUNNING" || robotstatus == "PREPARING") {
        console.log("Stopping execution: " + executionId);

        ExecutionsService.stopExecution({ executionId: executionId }).then(() => {
          this.showAlert("Execution stopped.", 'success');
          this.loadExecutions();
        }).catch((err) => {
          this.showAlert("An error occours stopping execution: " + err, 'danger');
        });
      } else {
        this.showAlert("This execution is not running.", 'danger');
      }

    }

  }

  killExecutionSubmit(executionId, robotstatus) {
    if (executionId.trim().length > 0 && robotstatus.trim().length > 0) {

      if (robotstatus == "RUNNING" || robotstatus == "PREPARING") {
        console.log("Killing execution: " + executionId);

        ExecutionsService.killExecution({ executionId: executionId }).then(() => {
          this.showAlert("Execution killed.", 'success');
          this.loadExecutions();
        }).catch((err) => {
          this.showAlert("An error occours killing execution: " + err, 'danger');
        });
      } else {
        this.showAlert("This execution is not running.", 'danger');
      }

    }

  }

  viewExecutionInfo(executionId, robotstatus, startDate, endDate, machineName, processName, origin, parameters) {

    if (executionId.trim().length > 0 &&
      robotstatus.trim().length > 0 &&
      startDate.trim().length > 0 &&
      machineName.trim().length > 0 &&
      processName.trim().length > 0
    ) {
      this.setState({
        infoexecutionId: executionId,
        inforobotstatus: robotstatus,
        infostartDate: startDate,
        infoendDate: endDate,
        infomachineName: machineName,
        infoprocessName: processName,
        infoorigin: origin,
        infoparameters: parameters
      });
      this.setModalInfoExecutionShow(true);
    }
  }

  viewExecutionLogs(executionId, robotstatus, processRef) {
    if (executionId.trim().length > 0 && robotstatus.trim().length > 0 && processRef.trim().length > 0) {
      console.log("Redirecting to: /admin/executions/logs/" + executionId);
      this.props.history.push("/admin/executions/logs/?executionId=" + executionId + "&robotstatus=" + robotstatus + "&processRef=" + processRef);
    }
  }

  loadExecutions() {
    const self = this;

    ExecutionsService.getExecutions().then(
      response => {
        var parsedExecutions = response.executions.map((execution, index) => {
          return (
            {
              executionId: execution.executionId,
              machineName: execution.machine.name,
              processName: execution.process.name,
              processRef: execution.process.processRef,
              action: execution.action,
              robotstatus: execution.robotstatus,
              createdAt: execution.createdAt,
              endAt: execution.endAt,
              origin: execution.origin,
              parameters: execution.parameters
            }
          )
        }).sort(function(a, b){
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        self.setState({ parsedExecutions: parsedExecutions })

      },
      error => {
        if(error && error.toString().includes('code ')){
          var errCode = error.toString().substring(error.toString().indexOf('code ')+5, error.toString().indexOf('code ')+8)
          if(errCode === "401"){
            window.location.href = "/login";
          }
        } else {
          console.error(error);
        }

      }
    );
  }

  convertDate(dt) {
    return ("0" + (dt.getDate())).slice(-2) + "/" + ("0" + (dt.getMonth() + 1)).slice(-2) + "/" + dt.getFullYear() + " " + ("0" + dt.getHours()).slice(-2) + ":" + ("0" + dt.getMinutes()).slice(-2) + ":" + ("0" + dt.getSeconds()).slice(-2);
  }

  render() {
    const columnsExecutions = [{
      dataField: 'processName',
      text: 'Process name'
    }, {
      dataField: 'machineName',
      text: 'Machine Name'
    }, {
      dataField: 'robotstatus',
      text: 'Status',
      formatter: (cellContent, row) => {
        if (row.robotstatus) {
                    
          if (row.action === "STOP") {
            return (
              <div>
                <FontAwesomeIcon icon={faStopCircle} /> STOPPED
              </div>
            );
          } else {
            if (row.robotstatus == "ENDED") {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#31d263' }} icon={faCheckCircle} /> {row.robotstatus}
                </div>
              );
            } else if (row.robotstatus == "PREPARING") {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#495057' }} icon={faEllipsisH} /> {row.robotstatus}
                </div>
              );
            } else if (row.robotstatus == "RUNNING") {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#FB5124' }} icon={faStopCircle} /> {row.robotstatus}
                </div>
              );
            } else if (row.robotstatus == "QUEUED") {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#FB5124' }} icon={faClock} /> {row.robotstatus}
                </div>
              );
            } else {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#ED145B' }} icon={faTimesCircle} /> {row.robotstatus}
                </div>
              );
            }
          }
        }
      }
    }, {
      dataField: 'createdAt',
      text: 'Start date',
      formatter: (cellContent, row) => {
        if (row.createdAt) {
          return (
            <div>{this.convertDate(new Date(row.createdAt))}</div>
          );
        }
      }
    }, {
      dataField: 'endAt',
      text: 'End date',
      formatter: (cellContent, row) => {
        if (row.endAt) {
          return (
            <div>{this.convertDate(new Date(row.endAt))}</div>
          );
        }
      }
    }, {
      dataField: 'origin',
      text: 'Origin', formatter: (cellContent, row) => {
        if (row.origin) {

          if (row.origin == "MANUAL") {
            return (
              <div>
                <FontAwesomeIcon icon={faMitten} /> {row.origin}
              </div>
            );
          } else if (row.origin.includes("SCHEDULED") || row.origin == "TRIGGERED") {
            var originShow = row.origin;
            if (row.origin.includes("SCHEDULED") && row.origin.includes("(")) {
              originShow = row.origin.substring(0, row.origin.indexOf('(') - 1)
            }

            return (
              <div>
                <FontAwesomeIcon icon={faBolt} /> {originShow}
              </div>
            );
          } else {
            return (
              <div>
                {row.origin}
              </div>
            );
          }

        }
      }
    }, {
      dataField: 'action',
      isDummyField: true,
      text: <OverlayTrigger
        placement="right"
        delay={{ show: 150, hide: 400 }}
        overlay={<Tooltip><FormattedMessage id="pages.admin.executions.grid.buttons.refresh" defaultMessage="Refresh" /></Tooltip>}
      >
        <Button variant="light" onClick={this.loadExecutions.bind(this)}><FontAwesomeIcon icon={faRedoAlt} /></Button>

      </OverlayTrigger>,
      formatter: (cellContent, row) => {
        if (row.executionId) {

          return (
            <Dropdown>
              <OverlayTrigger
                placement="right"
                delay={{ show: 150, hide: 400 }}
                overlay={<Tooltip>Actions</Tooltip>}
              >
                <Dropdown.Toggle variant="light">
                  <FontAwesomeIcon icon={faEllipsisV} />
                </Dropdown.Toggle>

              </OverlayTrigger>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => { this.viewExecutionInfo(row.executionId, row.robotstatus, row.createdAt, row.endAt, row.machineName, row.processName, row.origin, row.parameters) }}><FontAwesomeIcon icon={faInfoCircle} /> View info</Dropdown.Item>
                <Dropdown.Item disabled={!(row.robotstatus == "RUNNING" || row.robotstatus == "PREPARING")} onClick={() => { this.stopExecutionSubmit(row.executionId, row.robotstatus) }}><FontAwesomeIcon icon={faStopCircle} /> Stop</Dropdown.Item>
                <Dropdown.Item disabled={!(row.robotstatus == "RUNNING" || row.robotstatus == "PREPARING")} onClick={() => { this.killExecutionSubmit(row.executionId, row.robotstatus) }}><FontAwesomeIcon icon={faTimesCircle} /> Kill</Dropdown.Item>
                <Dropdown.Item onClick={() => { this.viewExecutionLogs(row.executionId, row.robotstatus, row.processRef) }}><FontAwesomeIcon icon={faList} /> View logs</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          );
        }
      }
    }];

    const columnsMachines = [{
      dataField: 'name',
      text: 'Name'
    }, {
      dataField: 'description',
      text: 'Description'
    }, {
      dataField: 'machineKey',
      text: 'Key'
    }, {
      dataField: 'status',
      text: 'Status', formatter: (cellContent, row) => {
        if (row.status) {

          if (new Date(row.wasOnlineAt) < new Date(new Date().getTime() - (60 * 60 * 1000))) {
            return (
              <div>
                <FontAwesomeIcon style={{ color: '#ED145B' }} icon={faMinusCircle} /> NOT RESPONDING
              </div>
            );
          } else {
            if (row.status == "IDLE") {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#31d263' }} icon={faCheckCircle} /> {row.status}
                </div>
              );
            } else if (row.status == "RUNNING" || row.status == "PREPARING") {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#FB5124' }} icon={faStopCircle} /> {row.status}
                </div>
              );
            } else {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#ED145B' }} icon={faTimesCircle} /> {row.status}
                </div>
              );
            }

          }

        }
      }
    }];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from} to { to} of { size} Results
      </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      noDataText: 'No data to show',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: 'All', value: this.state.parsedExecutions.length
      }]
    };

    return (
      <div className="container">

        <div id="alert" style={{zIndex: 2000, position: 'absolute', top: '20px', left: '35%', minWidth: '500px', textAlign: 'center'}}></div>

        <h2><FontAwesomeIcon icon={faPlayCircle} /> <FormattedMessage
          id="pages.admin.executions.maintitle"
          defaultMessage="Executions" /></h2>

        <OverlayTrigger
          placement="right"
          delay={{ show: 150, hide: 400 }}
          overlay={<Tooltip><FormattedMessage id="pages.admin.executions.grid.buttons.newexecution" defaultMessage="New execution" /></Tooltip>}
        >
          {this.state && this.state.executions &&
            <Button className={['btn-circle', 'btn-circle-right']} style={{left: '70%'}} variant="primary" onClick={() => this.setModalAddExecutionShow(true)}><FontAwesomeIcon icon={faPlay} /></Button>
          }

        </OverlayTrigger>
        <OverlayTrigger
          placement="right"
          delay={{ show: 150, hide: 400 }}
          overlay={<Tooltip><FormattedMessage id="pages.admin.executions.grid.buttons.newexecutionpackage" defaultMessage="New execution package" /></Tooltip>}
        >
          {this.state && this.state.executions &&
            <Button className={['btn-circle', 'btn-circle-right']} style={{left: '73%'}} variant="primary" onClick={() => this.setModalAddExecutionPackageShow(true)}><FontAwesomeIcon icon={faBox} /></Button>
          }

        </OverlayTrigger>
        <div className={['div-container-border']}>
          <BootstrapTable hover keyField='executionId' data={this.state.parsedExecutions} columns={columnsExecutions} pagination={paginationFactory(options)} />
        </div>

        <Modal
          show={this.state.modalAddExecutionShow}
          onHide={() => this.setModalAddExecutionShow(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            <FormattedMessage id="pages.admin.executions.modals.newexecution.title" defaultMessage="New execution" />
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={((e) => { e.preventDefault(); })}>

              {/* Type of execution select */}
              <Form.Group controlId="newexecutiontype">
                <Form.Label>Type</Form.Label>
                <select name="newexecutiontype" required onChange={this.myChangeHandler.bind(this)}>
                  <option selected>Robot</option>
                  <option>Command</option>
                </select>
              </Form.Group>

              {this.state && this.state.newexecutiontype == "Command" &&
                <Form.Group controlId="newexecutioncommand">
                  <Form.Label><FormattedMessage id="pages.admin.executions.modals.newexecution.command.label" defaultMessage="Execution command" /></Form.Label>
                  <Form.Control type="text" name="newexecutioncommand" onChange={this.myChangeHandler.bind(this)} required/>
                </Form.Group>
              }
              
              {/* Process select */}
              {this.state && this.state.newexecutiontype == "Robot" &&
                <Form.Group controlId="newExecutionProcess">
                  <Form.Label>Process name</Form.Label>
                  <select name="newexecutionprocessid" required onChange={this.myChangeHandler.bind(this)}>
                    <option disabled selected></option>
                    {this.state && this.state.processes &&
                      this.renderProcesses()
                    }
                  </select>
                </Form.Group>
              }
              
              <Tabs defaultActiveKey="machines">
                <Tab eventKey="machines" title="Machines" disabled={!this.state.newexecutionprocessid}>
                  {/* Machines table */}
                  <div className={['div-container-border']}>
                    <h5 className={['div-container-title']}><FormattedMessage id="pages.admin.executions.modals.newexecution.machineselect.label" defaultMessage="Select a machine" /></h5>

                    {this.state && this.state.machines && this.state.machines.length > 0 &&
                      <BootstrapTable keyField='id' data={this.state.machines} columns={columnsMachines} pagination={paginationFactory(options)} selectRow={{ mode: 'checkbox', clickToSelect: true, selected: this.state.machinesselected, onSelect: this.handleOnSelectMachines.bind(this), onSelectAll: this.handleOnSelectAllMachines.bind(this) }} />
                    }

                    {this.state && this.state.machines && this.state.machines.length == 0 &&
                      <div><FontAwesomeIcon icon={faCircleNotch} spin /> Loading machines...</div>
                    }

                  </div>
                </Tab>
                <Tab eventKey="parameters" title="Parameters" disabled={!this.state.newexecutionprocessid}>
                  <Form.Group controlId="newExecutionParameters">
                    <Form.Label><FormattedMessage id="pages.admin.executions.modals.newexecution.params.label" defaultMessage="Execution parameters" /></Form.Label>
                    <Form.Control type="text" name="newexecutionparameters" onChange={this.myChangeHandler.bind(this)} required placeholder="{'paramname': 'value', 'paramname': 'value'}" />
                  </Form.Group>
                </Tab>
              </Tabs>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={((!this.state.newexecutionprocessid && !this.state.newexecutioncommand) || (!this.state.machinesselected || this.state.machinesselected.length == 0))} onClick={this.addExecutionSubmit.bind(this)}><FormattedMessage id="pages.admin.executions.modals.newexecution.buttons.start" defaultMessage="Start" /></Button>
            <Button variant="transparent" onClick={() => this.setModalAddExecutionShow(false)}><FormattedMessage id="pages.admin.executions.modals.newexecution.buttons.close" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        {/* New execution package modal */}
        <Modal
          show={this.state.modalAddExecutionPackageShow}
          onHide={() => this.setModalAddExecutionPackageShow(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            <FormattedMessage id="pages.admin.executions.modals.newexecutionpackage.title" defaultMessage="New execution package" />
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={((e) => { e.preventDefault(); })}>

              <Form.Group controlId="newexecutionpackagecode">
                <Form.Label><FormattedMessage id="pages.admin.executions.modals.newexecutionpackage.code.label" defaultMessage="Package code" /></Form.Label>
                <Form.Control type="text" name="newexecutionpackagecode" onChange={this.myChangeHandler.bind(this)} required/>
              </Form.Group>

              <Form.Group controlId="newexecutionpackagedescription">
                <Form.Label><FormattedMessage id="pages.admin.executions.modals.newexecutionpackage.description.label" defaultMessage="Package description" /></Form.Label>
                <Form.Control type="text" name="newexecutionpackagedescription" onChange={this.myChangeHandler.bind(this)} required/>
              </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={(!this.state.newexecutionpackagedescription || !this.state.newexecutionpackagecode)} onClick={this.addExecutionSubmit.bind(this)}><FormattedMessage id="pages.admin.executions.modals.newexecutionpackage.buttons.create" defaultMessage="Create package" /></Button>
            <Button variant="transparent" onClick={() => this.setModalAddExecutionPackageShow(false)}><FormattedMessage id="pages.admin.executions.modals.newexecution.buttons.close" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

        {/* Info modal */}
        <Modal
          show={this.state.modalInfoExecutionShow}
          onHide={() => this.setModalInfoExecutionShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            <FormattedMessage id="pages.admin.executions.modals.executiondetails.title" defaultMessage="Execution details" />
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <span><b>Process name: </b>{this.state.infoprocessName}</span><br></br>
            <span><b>Execution status: </b>{this.state.inforobotstatus}</span><br></br>
            <span><b>Machine name: </b>{this.state.infomachineName}</span><br></br>
            <span><b>Start date: </b>{this.state.infostartDate}</span><br></br>
            <span><b>End date: </b>{(this.state.infoendDate && this.state.infoendDate.length > 0) ? this.state.infoendDate : 'Not ended'}</span><br></br>
            <span><b>Origin: </b>{this.state.infoorigin}</span><br></br>
            <span><b>Parameters: </b>{(this.state.infoparameters && this.state.infoparameters.length > 0 && this.state.infoparameters != '{}') ? <div style={{ marginLeft: '25px' }}>{this.transformExecutionParams(this.state.infoparameters)}</div> : 'Without parameters'}</span><br></br>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalInfoExecutionShow(false)}><FormattedMessage id="pages.admin.executions.modals.executiondetails.buttons.close" defaultMessage="Close" /></Button>
          </Modal.Footer>
        </Modal>

      </div>

    );
  }

  transformExecutionParams(paramsString) {
    var jsonParams = JSON.parse("{\"data\": [" + paramsString.replace(/\'/g, '\"').replace(/\,/g, '}, {') + "]}");
    return jsonParams.data.map((param, index) => {
      var firstValue = Object.keys(param)[0];
      return (
        <div><span><b>{Object.keys(param)[0]}: </b><span>{param[firstValue]}</span></span><br></br></div>
      )
    });
  }

  showAlert(text, type){
    ReactDOM.render(<Alert variant={type}>{text}</Alert>, document.getElementById('alert'));
    setTimeout(function(){ ReactDOM.unmountComponentAtNode(document.getElementById('alert')); }, 3000);
  }
}