import axios from 'axios';
import authHeader from './auth-header';
import * as config from "../config/constants.config";

export default new class MachinesService {
  getMyCompanyMachines() {
    const GETMACHINES_URL = "getMachines";
    return axios.get(config.API_URL + GETMACHINES_URL, { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  addMachine(form){
    const ADDMACHINE_URL = "addMachine";
    return axios.get(config.API_URL + ADDMACHINE_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  editMachine(form){
    const EDITMACHINE_URL = "editMachine";
    return axios.get(config.API_URL + EDITMACHINE_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  removeMachine(form){
    const REMOVEMACHINE_URL = "removeMachine";
    return axios.get(config.API_URL + REMOVEMACHINE_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

}
