import React, { Component } from "react";
import ReactDOM from 'react-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Cron from 'react-cron-generator';
import moment from 'moment';
import { DatetimePickerTrigger } from 'rc-datetime-picker';
import 'rc-datetime-picker/dist/picker.min.css'
import { Button, Modal, Form, OverlayTrigger, Tooltip, Dropdown, Tabs, Tab, Row, Col, ButtonGroup, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faEllipsisV, faPlus, faPencilAlt, faPlay, faTimesCircle, faClock, faFile, faCheckCircle, faStopCircle, faMinusCircle, faCircleNotch, faBolt } from '@fortawesome/free-solid-svg-icons'

import ProcessesService from "../../services/processes.service";
import MachinesService from "../../services/machines.service";
import TriggersService from "../../services/triggers.service";

import { FormattedMessage } from 'react-intl';

export default class Triggers extends Component {

  constructor(props) {
    super(props);

    this.state = {
      moment: moment(),

      modalAddTriggerShow: false,
      modalEditTriggerShow: false,
      modalRemoveTriggerShow: false,
      triggers: [],
      machines: [],
      processes: [],

      machinesselected: [],
      newtriggerdescription: '',
      newtriggertype: '',
      newtriggermachineid: '',
      newtriggerprocessid: '',
      newtriggerparams: '',
      newtriggerscheduledmode: '',
      newtriggerscheduledcron: '',
      newtriggerscheduleddate: moment().format('DD-MM-YYYY HH:mm:ss'),
      newtriggerscheduledcronadvanced: false,

      edittriggerid: '',
      edittriggerdescription: '',
      edittriggerlogpath: '',

      removetriggerid: '',
      removetriggerdescription: '',
      removetriggertriggerid: ''
    };

    this.loadProcesses();

  }

  /* Table behaviour */
  handleOnSelectMachines(row, isSelect) {

    if (isSelect) {
      if (row.status === "IDLE" && !(new Date(row.wasOnlineAt) < new Date(new Date().getTime() - (60 * 60 * 1000)))) {
        this.setState({
          machinesselected: this.state.machinesselected.concat(row.id)
        });
      } else {
        this.showAlert("This machine is already running or is disconnected.", 'danger');
        return false;
      }

    } else {
      this.setState({
        machinesselected: this.state.machinesselected.filter(x => x !== row.id)
      });
    }

  }

  handleOnSelectAllMachines(isSelect, rows) {
    const machines = rows.filter(r => (r.status === "IDLE" && !(new Date(r.wasOnlineAt) < new Date(new Date().getTime() - (60 * 60 * 1000))))).map(r => r.id);
    if (isSelect) {
      this.setState({
        machinesselected: machines
      });
    } else {
      this.setState({
        machinesselected: []
      });
    }
  }

  handleChangeDatepicker(moment) {
    this.setState({
      moment
    });
    
  }

  componentDidMount() {
    this.loadTriggers();
  }

  loadDisponibleMachines() {
    MachinesService.getMyCompanyMachines().then((res) => {
      this.setState({machines: res.machines});
    })
  }

  renderMachines() {
    return this.state.machines.map((machine, index) => {
      if (machine.status == "IDLE") {
        return (
          <option key={machine.id} value={machine.id}>{machine.name + " - " + machine.machineKey}</option>
        )
      }

    })
  }

  loadProcesses() {
    ProcessesService.getMyCompanyProcesses().then((res) => {
      this.state.processes = res.processes;

    })
  }

  renderProcesses() {
    return this.state.processes.map((process, index) => {
      return (
        <option key={process.id} value={process.id}>{process.name + " - " + process.processRef}</option>
      )

    })
  }

  setModalAddTriggerShow(bool) {
    this.loadDisponibleMachines();
    this.setState({ modalAddTriggerShow: bool });
  }

  setModalEditTriggerShow(bool) {
    this.setState({ modalEditTriggerShow: bool });
  }

  setModalRemoveTriggerShow(bool) {
    this.setState({ modalRemoveTriggerShow: bool });
  }

  myChangeHandler(event) {
    let nam = event.target.name;
    let val = event.target.value;
    
    if(event.target.type === "checkbox"){
      this.setState({ [nam]: event.target.checked })
    } else {
      this.setState({ [nam]: val })
    }
    
  }

  addTriggerSubmit() {
    if (this.state.newtriggerprocessid.trim().length > 0 &&
      this.state.newtriggertype.trim().length > 0 &&
      this.state.newtriggertype.trim().length > 0 &&
      this.state.newtriggerdescription.trim().length > 0) {

      var params = this.state.newtriggerparams;
      if (params.length === 0) {
        params = "{}";
      }

      var finaleCron = "";
      if(this.state.newtriggerscheduledcron.length > 0) {
        finaleCron = this.state.newtriggerscheduledcron.split(' ');
        var hourString = finaleCron[2];
        if(finaleCron.length === 7){
          finaleCron = finaleCron[0] + " " + 
                        finaleCron[1] + " " + 
                        hourString + " " + 
                        finaleCron[3] + " " + 
                        finaleCron[4] + " " + 
                        finaleCron[6];

        } else if(finaleCron.length === 6) {
          finaleCron = finaleCron[0] + " " + 
                        finaleCron[1] + " " + 
                        hourString + " " + 
                        finaleCron[3] + " " + 
                        finaleCron[4] + " " + 
                        finaleCron[5];

        } else {
          this.showAlert("Cron expression not valid", 'danger');
          return;
        }
        
      }

      var jsonTrigger = {
        description: this.state.newtriggerdescription,
        action: "addExecution",
        forceaction: false,
        processId: this.state.newtriggerprocessid,
        triggerType: this.state.newtriggertype,
        machines: this.state.machinesselected,
        parameters: params,
        scheduleType: (this.state.newtriggerscheduledmode === "FIXEDDATE" ? "DATE" : "CRON"),
        scheduledDate: (this.state.newtriggerscheduledmode === "FIXEDDATE" ? this.state.moment.toDate().toString() : finaleCron),
        timeZoneOffset: new Date().getTimezoneOffset() / 60
      };      

      TriggersService.addTrigger(jsonTrigger).then((res) => {
        this.showAlert("New trigger added successfully", 'success');
        this.setModalAddTriggerShow(false);
        this.loadTriggers();
      }).catch((err) => {
        this.showAlert("An error occours adding the new trigger: " + err, 'danger');
        this.setModalAddTriggerShow(false);
      })

      this.clearTemporalState();

    }

  }

  clearTemporalState() {
    this.setState({
      newtriggerdescription: '',
      newtriggerprocessid: '',
      newtriggertype: '',
      machinesselected: [],
      newtriggerscheduleddate: '',
      newtriggerscheduledcron: '',
      newtriggerparams: '',
      newtriggerscheduledcronadvanced: false
    })
  }

  removeTrigger(description, id, triggerid) {
    this.setState({ removetriggerid: id, removetriggerdescription: description, removetriggertriggerid: triggerid });
    this.setModalRemoveTriggerShow(true);
  }

  removeTriggerSubmit() {
    TriggersService.removeTrigger({ triggerId: this.state.removetriggertriggerid }).then((res) => {
      this.showAlert("Trigger removed successfully.", 'success');
      this.loadTriggers();
    }).catch((err) => {
      this.showAlert("An error occours removing the trigger: " + err, 'danger');
    })
    this.setModalRemoveTriggerShow(false)
  }

  editTrigger(id, name, description, logpath) {
    this.setState({
      edittriggerid: id,
      edittriggername: name,
      edittriggerdescription: description,
      edittriggerlogpath: logpath
    })
    this.setModalEditTriggerShow(true);
  }

  editTriggerSubmit() {
    if (this.state.edittriggerid.trim().length > 0 &&
      this.state.edittriggername.trim().length > 0 &&
      this.state.edittriggerdescription.trim().length > 0 &&
      this.state.edittriggerlogpath.trim().length > 0) {

      TriggersService.editTrigger({ id: this.state.edittriggerid, name: this.state.edittriggername, description: this.state.edittriggerdescription, logpath: this.state.edittriggerlogpath }).then((res) => {
        this.showAlert("Trigger edited successfully.", 'success');
        this.setModalEditTriggerShow(false);
        this.loadTriggers();
      }).catch((err) => {
        this.showAlert("An error occours editing the trigger: " + err, 'danger');
        this.setModalEditTriggerShow(false);
      })

    }
  }

  loadTriggers() {
    const self = this;

    TriggersService.getTriggers().then(
      response => {
        self.setState({ triggers: response.triggers })
      },
      error => {
        if(error && error.toString().includes('code ')){
          var errCode = error.toString().substring(error.toString().indexOf('code ')+5, error.toString().indexOf('code ')+8)
          if(errCode === "401"){
            window.location.href = "/login";
          }
        } else {
          console.error(error);
        }
      }
    );
  }

  componentDidMount() {
    this.loadTriggers();
  }

  render() {

    const columnsTriggers = [{
      dataField: 'triggerId',
      text: 'Trigger id'
    }, {
      dataField: 'description',
      text: 'Description'
    }, {
      dataField: 'triggerType',
      text: 'Trigger type', formatter: (cellContent, row) => {
        if (row.triggerType) {

          if (row.triggerType == "DATE") {
            return (
              <div>
                <FontAwesomeIcon icon={faClock} /> Scheduled date
              </div>
            );
          } else if (row.triggerType == "FILE") {
            return (
              <div>
                <FontAwesomeIcon icon={faFile} /> Search file
              </div>
            );
          } else {
            return (
              <div>
                {row.triggerType}
              </div>
            );
          }

        }
      }
    }, {
      dataField: 'dummyLookingfor',
      text: 'Looking for', formatter: (cellContent, row) => {
        if (row.triggerType) {

          if (row.triggerType === "DATE") {

            if(row.scheduleType === "DATE"){
              var jsonDate = JSON.parse(row.date);
              return (
                <div>
                  {
                    (jsonDate.date ? ("0" + (jsonDate.date)).slice(-2) : '*') + "/" +
                    (jsonDate.month ? ("0" + (jsonDate.month)).slice(-2) : '*') + "/" +
                    (jsonDate.year ? jsonDate.year : '*') + " " +
                    (jsonDate.hour ? ("0" + (jsonDate.hour)).slice(-2) : '*') + ":" +
                    (jsonDate.minute ? ("0" + (jsonDate.minute)).slice(-2) : '*') + ":" +
                    (jsonDate.second ? ("0" + (jsonDate.second)).slice(-2) : '*')
                  }
                </div>
              );
            } else if (row.scheduleType === "CRON"){
              return (
                <div>
                  {
                    row.date
                  }
                </div>
              );
            }
            
          } else if (row.triggerType == "FILE") {
            return (
              <div>
                {row.path}
              </div>
            );
          } else {
            return (
              <div>
                -
              </div>
            );
          }

        }
      }
    }, {
      dataField: 'action',
      text: 'Action', formatter: (cellContent, row) => {
        if (row.action) {

          if (JSON.parse(row.action).action == "addExecution") {
            return (
              <div>
                <FontAwesomeIcon icon={faPlay} /> Run execution
              </div>
            );
          } else {
            return (
              <div>
                {JSON.parse(row.action).action}
              </div>
            );
          }

        }
      }
    }, {
      dataField: 'actionDummy',
      isDummyField: true,
      text: <OverlayTrigger
        placement="right"
        delay={{ show: 150, hide: 400 }}
        overlay={<Tooltip><FormattedMessage id="pages.admin.triggers.grid.buttons.refresh" defaultMessage="Refresh" /></Tooltip>}
      >
        <Button variant="light" onClick={this.loadTriggers.bind(this)}><FontAwesomeIcon icon={faRedoAlt} /></Button>

      </OverlayTrigger>,
      formatter: (cellContent, row) => {
        if (row.triggerId) {

          return (
            <Dropdown>
              <OverlayTrigger
                placement="right"
                delay={{ show: 150, hide: 400 }}
                overlay={<Tooltip>Actions</Tooltip>}
              >
                <Dropdown.Toggle variant="light">
                  <FontAwesomeIcon icon={faEllipsisV} />
                </Dropdown.Toggle>

              </OverlayTrigger>

              <Dropdown.Menu>
                <Dropdown.Item disabled onClick={() => { this.editTrigger(row.id, row.name, row.description, row.logPath) }}><FontAwesomeIcon icon={faPencilAlt} /> Edit trigger</Dropdown.Item>
                <Dropdown.Item onClick={() => { this.removeTrigger(row.description, row.id, row.triggerId) }}><FontAwesomeIcon icon={faTimesCircle} /> Remove trigger</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

          );
        }
      }
    }];

    const columnsMachines = [{
      dataField: 'name',
      text: 'Name'
    }, {
      dataField: 'description',
      text: 'Description'
    }, {
      dataField: 'machineKey',
      text: 'Key'
    }, {
      dataField: 'status',
      text: 'Status', formatter: (cellContent, row) => {
        if (row.status) {

          if (new Date(row.wasOnlineAt) < new Date(new Date().getTime() - (60 * 60 * 1000))) {
            return (
              <div>
                <FontAwesomeIcon style={{ color: '#ED145B' }} icon={faMinusCircle} /> NOT RESPONDING
              </div>
            );
          } else {
            if (row.status == "IDLE") {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#31d263' }} icon={faCheckCircle} /> {row.status}
                </div>
              );
            } else if (row.status == "RUNNING" || row.status == "PREPARING") {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#FB5124' }} icon={faStopCircle} /> {row.status}
                </div>
              );
            } else {
              return (
                <div>
                  <FontAwesomeIcon style={{ color: '#ED145B' }} icon={faTimesCircle} /> {row.status}
                </div>
              );
            }

          }

        }
      }
    }];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from} to { to} of { size} Results
      </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      noDataText: 'No data to show',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: 'All', value: this.state.triggers.length
      }]
    };


    return (

      <div className="container">

        <div id="alert" style={{zIndex: 2000, position: 'absolute', top: '20px', left: '35%', minWidth: '500px', textAlign: 'center'}}></div>

        <h2><FontAwesomeIcon icon={faBolt} /> <FormattedMessage
          id="pages.admin.triggers.maintitle"
          defaultMessage="Triggers" /></h2>

        <OverlayTrigger
          placement="right"
          delay={{ show: 150, hide: 400 }}
          overlay={<Tooltip><FormattedMessage id="pages.admin.triggers.grid.buttons.newtrigger" defaultMessage="Add trigger" /></Tooltip>}
        >
          {this.state && this.state.triggers &&
            <Button className={['btn-circle', 'btn-circle-right']} style={{left: '73%'}} variant="primary" onClick={() => this.setModalAddTriggerShow(true)}><FontAwesomeIcon icon={faPlus} /></Button>
          }

        </OverlayTrigger>
        <div className={['div-container-border']}>
          <BootstrapTable hover keyField='triggerId' data={this.state.triggers} columns={columnsTriggers} pagination={paginationFactory(options)} />
        </div>


        {/* Add trigger modal */}
        <Modal
          show={this.state.modalAddTriggerShow}
          onHide={() => this.setModalAddTriggerShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Add trigger
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={((e) => { e.preventDefault(); })}>

              <Form.Group controlId="newTriggerDescription">
                <Form.Label>Trigger description</Form.Label>
                <Form.Control type="text" name="newtriggerdescription" onChange={this.myChangeHandler.bind(this)} required placeholder="Enter trigger description" />
              </Form.Group>

              {/* Process select */}
              <Form.Group controlId="newTriggerProcess">
                <Form.Label>Process name</Form.Label>
                <select name="newtriggerprocessid" required onChange={this.myChangeHandler.bind(this)}>
                  <option disabled selected></option>
                  {this.state && this.state.processes &&
                    this.renderProcesses()
                  }
                </select>
              </Form.Group>

              {/* Trigger type select */}
              <Form.Group controlId="newTriggerType">
                <Form.Label>Trigger type</Form.Label>
                <select name="newtriggertype" required onChange={this.myChangeHandler.bind(this)}>
                  <option disabled selected></option>
                  <option value="DATE">Scheduled date</option>
                  <option value="FILE" disabled>File search</option>
                </select>
              </Form.Group>

              <Tabs defaultActiveKey="machines">
                <Tab eventKey="machines" title="Machines" disabled={!this.state.newtriggerprocessid}>
                  {/* Machines table */}
                  <div className={['div-container-border']}>
                    <h5 className={['div-container-title']}>Select a machine</h5>

                    {this.state && this.state.machines && this.state.machines.length > 0 &&
                      <BootstrapTable keyField='id' data={this.state.machines} columns={columnsMachines} pagination={paginationFactory(options)} selectRow={{ mode: 'checkbox', clickToSelect: true, selected: this.state.machinesselected, onSelect: this.handleOnSelectMachines.bind(this), onSelectAll: this.handleOnSelectAllMachines.bind(this) }} />
                    }

                    {this.state && this.state.machines && this.state.machines.length == 0 &&
                      <div><FontAwesomeIcon icon={faCircleNotch} spin /> Loading machines...</div>
                    }

                  </div>
                </Tab>
                <Tab eventKey="parameters" title="Parameters" disabled={!this.state.newtriggerprocessid}>
                  <Form.Group controlId="newExecutionParameters">
                    <Form.Label>Execution parameters</Form.Label>
                    <Form.Control type="text" name="newtriggerparameters" onChange={this.myChangeHandler.bind(this)} required placeholder="{'paramname': 'value', 'paramname': 'value'}" />
                  </Form.Group>
                </Tab>
                <Tab eventKey="scheduledDate" title="Scheduled date" disabled={!this.state.newtriggertype || this.state.newtriggertype === ""}>
                  <Row>
                    <Col xs="2" style={{borderRight: '1px solid #DEE2E6'}}>
                      <label><input value="EVERYX" id="radioTriggerScheduledModeEveryX" type="radio" name="newtriggerscheduledmode" checked={this.state.newtriggerscheduledmode === "EVERYX"} onChange={this.myChangeHandler.bind(this)}/> Every X</label>
                      <label><input value="FIXEDDATE" id="radioTriggerScheduledModeFixedDate" type="radio" name="newtriggerscheduledmode" checked={this.state.newtriggerscheduledmode === "FIXEDDATE"} onChange={this.myChangeHandler.bind(this)}/> Fixed date</label>
                    </Col>
                    <Col xs="10" style={{backgroundColor: '#dddef13d'}}>
                        {
                          this.state && this.state.newtriggerscheduledmode === "EVERYX" && <div>
                            <div><b>Hours must be in UTC-4 format.</b> Your timezone offset is UTC+{new Date().getTimezoneOffset() / 60 * -1}</div>
                            <div disabled={this.state.newtriggerscheduledcronadvanced}>
                              <Cron
                                onChange={(e)=> {this.setState({newtriggerscheduledcron:e});}}
                                value={this.state.newtriggerscheduledcron}
                                showResultText={true}
                                showResultCron={false}
                                />
                            </div>

                              <label><input value="true" type="checkbox" name="newtriggerscheduledcronadvanced" onChange={this.myChangeHandler.bind(this)} checked={this.state.newtriggerscheduledcronadvanced}/> Cron expresion (Advanced) </label>
                              <input type="text" name="newtriggerscheduledcron" onChange={this.myChangeHandler.bind(this)} disabled={!this.state.newtriggerscheduledcronadvanced} />
                          </div>
                        }
                        {
                          this.state && this.state.newtriggerscheduledmode === "FIXEDDATE" && <div style={{padding: '20px'}}>
                            {/* Start job on <input type="input" name="newtriggerscheduleddate" onChange={this.myChangeHandler.bind(this)} placeholder="DD-MM-YYYY HH:mm:ss" size="50"/> */}
                            Start job on 
                            <DatetimePickerTrigger
                              shortcuts={{
                                'Today': moment(),
                                'Yesterday': moment().subtract(1, 'days')
                              }} 
                              moment={this.state.moment}
                              onChange={this.handleChangeDatepicker.bind(this)}>
                              <input type="text" value={this.state.moment.format('DD-MM-YYYY HH:mm:00')} name="newtriggerscheduleddate" onChange={this.myChangeHandler.bind(this)} />
                            </DatetimePickerTrigger>
                            
                          </div>
                        }
                    </Col>
                  </Row>
                </Tab>
              </Tabs>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" disabled={(!this.state.newtriggerprocessid || !this.state.newtriggertype || !this.state.newtriggerdescription || !this.state.newtriggerscheduledmode || (!this.state.newtriggerscheduledcron && !this.state.newtriggerscheduleddate) || (!this.state.machinesselected || this.state.machinesselected.length == 0))} onClick={this.addTriggerSubmit.bind(this)}>
              Submit
            </Button>
            <Button onClick={() => {this.setModalAddTriggerShow(false); this.clearTemporalState();} }>Close</Button>
          </Modal.Footer>
        </Modal>

        {/* Edit trigger modal */}
        <Modal
          show={this.state.modalEditTriggerShow}
          onHide={() => this.setModalEditTriggerShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Edit trigger
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={((e) => { e.preventDefault(); })}>
              <Form.Group controlId="editTriggerDescription">
                <Form.Label>Trigger description</Form.Label>
                <Form.Control type="text" name="edittriggerdescription" value={this.state.edittriggerdescription} onChange={this.myChangeHandler.bind(this)} required placeholder="Enter trigger description" />
              </Form.Group>

              <Form.Group controlId="editTriggerLogPath">
                <Form.Label>Trigger logs path</Form.Label>
                <Form.Control type="text" name="edittriggerlogpath" value={this.state.edittriggerlogpath} onChange={this.myChangeHandler.bind(this)} required placeholder="Enter trigger log path folder" />
              </Form.Group>

              <Button variant="primary" type="submit" onClick={this.editTriggerSubmit.bind(this)}>
                Submit
            </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.setModalEditTriggerShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

        {/* Remove trigger modal */}
        <Modal
          show={this.state.modalRemoveTriggerShow}
          onHide={() => this.setModalRemoveTriggerShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>Remove trigger</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to remove '{this.state.removetriggerdescription}' with TriggerKey '{this.state.removetriggertriggerid}'</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setModalRemoveTriggerShow(false)}>Close</Button>
            <Button variant="primary" onClick={this.removeTriggerSubmit.bind(this)}>Remove</Button>
          </Modal.Footer>
        </Modal>

      </div>

    );
  }

  showAlert(text, type){
    ReactDOM.render(<Alert variant={type}>{text}</Alert>, document.getElementById('alert'));
    setTimeout(function(){ ReactDOM.unmountComponentAtNode(document.getElementById('alert')); }, 3000);
  }
}