import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Navbar, Container, Nav } from 'react-bootstrap';

import * as constants from "./config/constants.config";

import AuthService from "./services/auth.service";

import Home from "./components/home.component";
import Login from "./components/login.component";
import Register from "./components/register.component";
import ChangePassword from "./components/changepassword.component";
import Profile from "./components/profile.component";
import BoardAdmin from "./components/board-admin.component";

import { FormattedMessage } from 'react-intl';

class App extends Component {

  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      isCompanyAdmin: false,
      isSuperAdmin: false,
      isNormalUser: undefined,
      userLogged: false
    };

  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();
    // fetch(require('./gitInfo.txt')).then(result => {
    //   result.text().then((text) => {
    //     this.setState({ version: text.split(" ")[0] });
    //   })
    // })
    this.setState({ version: `${process.env.VERCEL_GITHUB_COMMIT_SHA}` });

    if (user) {
      this.setState({
        isNormalUser: AuthService.getCurrentUser(),
        isCompanyAdmin: user.roles.includes("ROLE_COMPANYADMIN"),
        isSuperAdmin: user.roles.includes("ROLE_SUPERADMIN"),
        userLogged: true
      });
    }

    console.log(process.env.VERCEL_GITHUB_COMMIT_SHA);
    
  }

  logOut() {
    AuthService.logout();
  }

  render() {
    const { isNormalUser, isCompanyAdmin, isSuperAdmin } = this.state;

    return (
      <Router>
        <div>
          <nav className="navbar navbar-expand navbar-dark bg-dark nav-bar-main">
            <Navbar.Brand href="/">
              <img src={process.env.PUBLIC_URL + '/images/logobotbrain.png'} width="50px" /> {constants.APP_NAME}
            </Navbar.Brand>
            <div className="navbar-nav mr-auto">

              {(isCompanyAdmin || isSuperAdmin) && (
                <li className="nav-item">
                  <Link to={"/admin/executions"} className="nav-link">
                    <FormattedMessage
                      id="navbar.top.adminboard.title"
                      defaultMessage="Admin board" />
                  </Link>
                </li>
              )}

              {isSuperAdmin && (
                <li className="nav-item">
                  <Link to={"/superadmin"} className="nav-link">
                    <FormattedMessage
                      id="navbar.top.superadminboard.title"
                      defaultMessage="Super admin things" />
                  </Link>
                </li>
              )}

            </div>

            {isNormalUser ? (
              <div className="navbar-nav ml-auto">
                {/* <li className="nav-item">
                  <Link to={"/user"} className="nav-link">
                    User
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link to={"/profile"} className="nav-link">
                    {isNormalUser.name}
                  </Link>
                </li>
                <li className="nav-item">
                  <a href="/login" className="nav-link" onClick={this.logOut}>
                    <FormattedMessage
                      id="navbar.top.adminboard.logout"
                      defaultMessage="Log out" />
                  </a>
                </li>
              </div>
            ) : (
                <div className="navbar-nav ml-auto">
                  <li className="nav-item">
                    <Link to={"/login"} className="nav-link">
                      <FormattedMessage
                        id="navbar.top.adminboard.login"
                        defaultMessage="Entrar" />
                    </Link>
                  </li>

                  {/* <li className="nav-item">
                  <Link to={"/register"} className="nav-link">
                    Sign Up
                  </Link>
                </li> */}
                </div>
              )}
          </nav>

          <div style={{ marginBottom: '100px', marginLeft: '160px' }}>
            <Switch>
              <Route exact path={["/", "/home"]} component={Home} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path={"/" + constants.REGISTER_MASTER} component={Register} />
              <Route exact path="/changepassword" component={ChangePassword} />
              <Route path="/profile" component={Profile} />
              {/* <Route path="/user" component={BoardUser} /> */}
              <Route path="/admin" component={BoardAdmin} />
            </Switch>
          </div>
          
          <Navbar className="navbar navbar-dark bg-dark" fixed="bottom" style={{ height: '40px' }}>
            <Container>
              <Nav.Link style={{ color: "white" }} onClick={() => window.open("https://hangouts.google.com/chat/person/113040581554894894301", "_blank")}><FormattedMessage id="navbar.bot.needsupport" defaultMessage="Do you need support?" /> <img width="25px" src="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4QAqRXhpZgAASUkqAAgAAAABADEBAgAHAAAAGgAAAAAAAABHb29nbGUAAP/bAIQAAwICAwICAwMDAwQDAwQFCAUFBAQFCgcHBggMCgwMCwoLCw0OEhANDhEOCwsQFhARExQVFRUMDxcYFhQYEhQSFAEDBAQFBAUJBQUKFQ0LDRESFRUOFQ8QFBIQEBQODRUUFRINEBUPFBUQFBAPDRUODxUUDw0ODxIVEA0VDQ0NDRUP/8AAEQgAIAAgAwERAAIRAQMRAf/EABkAAAIDAQAAAAAAAAAAAAAAAAcIAwYJBP/EACsQAAEDAgUDAwQDAAAAAAAAAAECAwQFEQAGEiFBBxMxCCJxFBVhgRZRof/EABoBAQADAQEBAAAAAAAAAAAAAAYDBAUCAAH/xAAkEQACAgIBAwQDAAAAAAAAAAABAgADESESBBMxQVFhwUKh0f/aAAwDAQACEQMRAD8Ad7qhnaF016f1jM1Q1GJTYxeUhA3WrYJSPyVFI/eOD8TRcgDJmV2cOvXVjO2YPuD9drrff97UalOOx2G0caENkbfncnknEoFYGz+5iubmOgd/Bhl9Pfqtz3lnONEouc3KjWKFU30Q0/cWyZUcqUEocS4oBTguRqCirbcb+fAr+Jnw9xccx5+JoI6jbzjzDU7EpfqRyvEzf0aq9IluJbbkKjKSFKtrLb7bmgfIQRiG1uCFptio3HhjP8zAjRnaM5UPoIzzHfZGzKRbYG23BHxgxxI2YsVkOhJZkHLWe3IDDehx6mVSJKU72VjtFp9BUb239urxwcWqGaqwMTqZnWonU1FEGW+4zjUlqfEZkx3A6w8hLrbiTspJFwR8g4SaIyIOZSjFT5Ejz5lz+T5UehJOl8aHWV8haTf/AEXT8KOK11fdrKjzEFF3YsDen1FNqTVHy3m1mW4wlEkgEvBI1WJPJtfcYOplxx9om4qG5Ablx6bUiDXXpMeFC+iU64Gy4lGjYgKJBF7/AKuL4kCF7Fr9ZWtsSitrceIxIYbZYbaaQG2m0hCUJFgkAWAGFGNYEAlixLHzOPMuaF0eiSZMWKqe9Ha7naCtANvPusfHnxxihZ1S1jI3EC0dxgpOMxd6kmpSaiZLUAS2JCiopbXoUjUb234weDBiW8Gb551gKBkS6ZTk1GjPJqMyGnW2CERkOX2ItfVbzv8A1iSu9aLA43K11D9VUUbXtC21IW7GZdU0tnuISvQ4BqTcXsbcjCpX5AGDGXiSJ//Z" /></Nav.Link>
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <span style={{ color: "white", fontSize: "12px" }}>{constants.VERSION} {this.state && this.state.version != "undefined" && this.state.version}</span>
                </li>
              </div>
            </Container>
          </Navbar>
        </div>
      </Router>
    );
  }
}

export default App;