import axios from 'axios';
import authHeader from './auth-header';
import * as config from "../config/constants.config";

export default new class WorkflowsService {
  getWorkflows() {
    const GETWORKFLOWS_URL = "getWorkflows";
    return axios.get(config.API_URL + GETWORKFLOWS_URL, { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  addWorkflow(form){
    const ADDWORKFLOW_URL = "addWorkflow";
    return axios.get(config.API_URL + ADDWORKFLOW_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  editWorkflow(form){
    const EDITWORKFLOW_URL = "editWorkflow";
    return axios.get(config.API_URL + EDITWORKFLOW_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  removeWorkflow(form){
    const REMOVEWORKFLOW_URL = "removeWorkflow";
    return axios.get(config.API_URL + REMOVEWORKFLOW_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

}
