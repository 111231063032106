import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "../services/auth.service";

import { FormattedMessage } from 'react-intl';

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser()
    };
  }

  render() {
    const { currentUser } = this.state;

    if (!currentUser) {
      return (<Redirect to='/login' />);
    } else {
      return (
        <div className="container">
          <header className="jumbotron">
            <h3>
              <strong>{currentUser.name} {currentUser.surname}</strong>
            </h3>
            {/* <p>
              <br></br>
              <strong>Token:</strong>{" "}
              {currentUser.accessToken.substring(0, 20)} ...{" "}
              {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
            </p> 
            <p>
              <strong>Id:</strong>{" "}
              {currentUser.id}
            </p>
            */}
            <p>
              <strong>Email:</strong>{" "}
              {currentUser.email}
            </p>
            <strong>Authorities:</strong>
            <ul>
              {currentUser.roles &&
                currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
            </ul>
          </header>
        </div>
      );
    }
  }
}