import axios from 'axios';
import authHeader from './auth-header';
import * as config from "../config/constants.config";

export default new class TriggersService {
  getTriggers() {
    const GETTRIGGERS_URL = "getTriggers";
    return axios.get(config.API_URL + GETTRIGGERS_URL, { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  addTrigger(form){
    const ADDTRIGGER_URL = "createTrigger";
    return axios.get(config.API_URL + ADDTRIGGER_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  editTrigger(form){
    const EDITTRIGGER_URL = "editTrigger";
    return axios.get(config.API_URL + EDITTRIGGER_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  removeTrigger(form){
    const REMOVETRIGGER_URL = "removeTrigger";
    return axios.get(config.API_URL + REMOVETRIGGER_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

}
