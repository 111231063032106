import React, { Component } from "react";
import ReactDOM from 'react-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Button, Modal, Form, OverlayTrigger, Tooltip, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faPlus, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import LogsService from "../../services/logs.service";
import ExecutionsService from "../../services/executions.service";

import { FormattedMessage } from 'react-intl';

export default class Logs extends Component {

  constructor(props) {
    super(props);

    this.state = {
      executionId: '',
      robotstatus: '',
      processRef: '',
      logs: [],
      base64img: ''
    };
  }

  componentDidMount() {
    this.getUrlParams();

    try {
      setInterval(async () => {
        if(this.state.robotstatus == "RUNNING"){
          ExecutionsService.showScreenview({ showScreen: true, executionId: this.state.executionId }).then((res) => {
            if(res.base64img){
              this.setState({ base64img: "data:image/jpeg;base64," + res.base64img });
            }
            
          })
        }
        
      }, 2000);
    } catch(e) {
      console.log(e);
    }

  }

  getUrlParams() {
    const urlParams = new URLSearchParams(window.location.search);
    var executionId = urlParams.get("executionId");
    var robotstatus = urlParams.get("robotstatus");
    var processRef = urlParams.get("processRef");

    if (robotstatus.length === 0) {
      robotstatus = "IDLE";
    }
    this.setState({ executionId: executionId, robotstatus: robotstatus, processRef: processRef }, this.loadLogs);

  }

  setModalInfoLogShow(bool) {
    this.setState({ modalInfoLogShow: bool });
  }

  loadLogs() {
    const self = this;

    LogsService.getLogs({ executionId: this.state.executionId, processRef: this.state.processRef }).then(
      response => {
        if (!response || !response.logs || !response.logs.logs || response.logs.logs.length === 0) {
          if (response.error.indexOf('requested to service') !== -1) {
            this.showAlert("No local logs found on server, requested to service. Please retry in a few seconds.", 'danger');
          }
        } else {
          var logs = response.logs.logs;

          self.setState({ logs: logs })
          console.log(this.state.logs);
        }

      },
      error => {
        if (error && error.toString().includes('code ')) {
          var errCode = error.toString().substring(error.toString().indexOf('code ') + 5, error.toString().indexOf('code ') + 8)
          if (errCode === "401") {
            window.location.href = "/login";
          }
        } else {
          console.error(error);
        }
      }
    );
  }

  showLogInfo(message, level, timeStamp, machineName, processName, processVersion, fileName) {
    this.setState({
      infomessage: message,
      infolevel: level,
      infotimeStamp: timeStamp,
      infomachineName: machineName,
      infoprocessName: processName,
      infoprocessRef: this.state.processRef,
      infoprocessVersion: processVersion,
      infoexecutionId: this.state.executionId,
      infofileName: fileName
    })

    this.setModalInfoLogShow(true);
  }

  render() {

    const columnsLogs = [{
      dataField: 'timeStamp',
      text: 'Time'
    }, {
      dataField: 'level',
      text: 'Level'
    }, {
      dataField: 'processName',
      text: 'Process name'
    }, {
      dataField: 'processVersion',
      text: 'Process version'
    }, {
      dataField: 'machineName',
      text: 'Machine name'
    }, {
      dataField: 'message',
      text: 'Message',
      formatter: (cellContent, row) => {
        if (row.message.length > 60) {
          return (
              <div><span>{row.message.substring(0, 60) + "..."}</span> <Button variant="light" onClick={() => { this.showLogInfo(row.message, row.level, row.timeStamp, row.machineName, row.processName, row.processVersion, row.fileName) }}><FontAwesomeIcon icon={faInfoCircle} /> More</Button></div>
            );
        } else {
          return (
            <div>{row.message}</div>
          );
        }
      }
    }, {
      dataField: 'action',
      isDummyField: true,
      text: <OverlayTrigger
        placement="right"
        delay={{ show: 150, hide: 400 }}
        overlay={<Tooltip><FormattedMessage id="pages.admin.logs.grid.buttons.refresh" defaultMessage="Refresh" /></Tooltip>}
      >
        <Button variant="light" onClick={this.loadLogs.bind(this)}><FontAwesomeIcon icon={faRedoAlt} /></Button>

      </OverlayTrigger>,
      formatter: (cellContent, row) => {
        if (row.message) {
          return (
            <Button variant="light" onClick={() => { this.showLogInfo(row.message, row.level, row.timeStamp, row.machineName, row.processName, row.processVersion, row.fileName) }}><FontAwesomeIcon icon={faInfoCircle} /> View info</Button>
          );
        }
      }
    }];

    const customTotal = (from, to, size) => (
      <span className="react-bootstrap-table-pagination-total">
        Showing { from} to { to} of { size} Results
      </span>
    );

    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      noDataText: 'No data to show',
      showTotal: true,
      paginationTotalRenderer: customTotal,
      disablePageTitle: true,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: 'All', value: this.state.logs.length
      }]
    };

    return (<div>{(this.state.executionId.length > 0) &&

      <div className="" style={{marginLeft: '80px', marginTop: '50px', width: 'fit-content', maxWidth: '82vw'}}>

        <div id="alert" style={{ zIndex: 2000, position: 'absolute', top: '20px', left: '35%', minWidth: '500px', textAlign: 'center' }}></div>

        <h2><FormattedMessage
          id="pages.admin.logs.maintitle"
          defaultMessage="Execution logs" /> </h2>
        Execution ID: {this.state.executionId}

        {(this.state && this.state.base64img.length > 0) && 
          <div className={['div-container-border div-image-screenshow-small']} style={{position: 'absolute', top: '5px', right: '22px'}}>
            <img style={{width: '100%', height: '100%'}} src={this.state.base64img}></img>
          </div>}

        <div className={['div-container-border']}>
          <BootstrapTable hover keyField='machineKey' options={{ noDataText: 'No data to show' }} data={this.state.logs} columns={columnsLogs} pagination={paginationFactory(options)} />
        </div>

        {/* Info modal */}
        <Modal
          show={this.state.modalInfoLogShow}
          onHide={() => this.setModalInfoLogShow(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="modal-large"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            <FormattedMessage id="pages.admin.logs.modals.logdetails.title" defaultMessage="Log details" />
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <span><b>Message: </b>{this.state.infomessage}</span><br></br>
            <span><b>Log level: </b>{this.state.infolevel}</span><br></br>
            <span><b>Timestamp: </b>{this.state.infotimeStamp}</span><br></br>
            <span><b>Machine name: </b>{this.state.infomachineName}</span><br></br>
            <span><b>Process name: </b>{this.state.infoprocessName}</span><br></br>
            <span><b>Process ref: </b>{this.state.infoprocessRef}</span><br></br>
            <span><b>Process version: </b>{this.state.infoprocessVersion}</span><br></br>
            <span><b>Execution id: </b>{this.state.infoexecutionId}</span><br></br>
            <span><b>Activity log file name: </b>{this.state.infofileName}.xaml</span><br></br>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="transparent" onClick={() => this.setModalInfoLogShow(false)}>Close</Button>
          </Modal.Footer>
        </Modal>

      </div>

    }</div>);
  }

  showAlert(text, type) {
    ReactDOM.render(<Alert variant={type}>{text}</Alert>, document.getElementById('alert'));
    setTimeout(function () { ReactDOM.unmountComponentAtNode(document.getElementById('alert')); }, 3000);
  }
}