import axios from 'axios';
import authHeader from './auth-header';
import * as config from "../config/constants.config";

export default new class ExecutionsService {
  getExecutions() {
    const GETEXECUTIONS_URL = "getExecutions";
    return axios.get(config.API_URL + GETEXECUTIONS_URL, { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  addExecution(form){
    const ADDEXECUTION_URL = "addExecution";
    return axios.get(config.API_URL + ADDEXECUTION_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  stopExecution(form){
    const STOPEXECUTION_URL = "stopExecution";
    return axios.get(config.API_URL + STOPEXECUTION_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  killExecution(form){
    const KILLEXECUTION_URL = "killExecution";
    return axios.get(config.API_URL + KILLEXECUTION_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  showScreenview(form){
    const SHOWSCREEN_URL = "showScreenview";
    return axios.get(config.API_URL + SHOWSCREEN_URL + "?" + new URLSearchParams(form).toString(), { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

}
